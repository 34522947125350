import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { types } from '../../../../types/types';

import { ModalEditarPartes } from '../modalsAmparo/ModalEditarPartes';

import { ActualizarAmparoAutoridadAmparo, ActualizarAmparoNumeroOrigen, ActualizarAmparoTipoAmparo, GenerarCaratula } from '../../../../actions/recepcion/amparoActions';

import { ObtenerTiposAmparos } from '../../../../actions/admin/catalogs/tipoAmparoActions';
import { ObtenerAutoridadesAmparo } from '../../../../actions/admin/catalogs/autoridadAmparoActions';

export const DetalleAmparoScreen = ({ edit, setNoReferencia }) => {

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );
    const { acuerdo, amparo, expedientilloSuspension } = useSelector( state => state.acuerdo );

    const [loading, setLoading] = useState( false );
    const [openCaratula, setOpenCaratula] = useState( false );

    const [loadingConfirmar, setLoadingConfirmar] = useState( false );
    const [openConfirmar, setOpenConfirmar] = useState( false );

    const [open, setOpen] = useState( false );

    const [numeroAmparoOrigen, setNumeroAmparoOrigen] = useState( '' );

    const [tipoAmparo, setTipoAmparo] = useState( 0 );
    const [tipoAmparoArray, setTipoAmparoArray] = useState( [ ] );

    const [autoridadAmparo, setAutoridadAmparo] = useState( 0 );
    const [autoridadAmparoArray, setAutoridadAmparoArray] = useState( [ ] );

    const [loadingSaveTipoAmparo, setLoadingSaveTipoAmparo] = useState( false );
    const [loadingSaveAutoridadAmparo, setLoadingSaveAutoridadAmparo] = useState( false );
    const [loadingSaveNumeroOrigen, setLoadingSaveNumeroOrigen] = useState( false );

    const [alert, setAlert] = useState( {} );

    const handleOpenCaratula = async () => {

        setLoading( true );

        await GenerarCaratula( amparo?.idAmparo ?? 0 ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){

                        dispatch({

                            type: types.actualizarAmparoAcuerdo,
                            payload: {                                
                                ...amparo,
                                caratula: response.data.caratula,
                            }
                
                        });
                        
                        setLoading( false );

                        setOpenCaratula( true );                     

                    }

                }

            }

        });    
        
    }

    const eliminarCaratula = () => {

        setLoadingConfirmar( true );

        setTimeout(() => {

            dispatch({

                type: types.actualizarAmparoAcuerdo,
                payload: {                                
                    ...amparo,
                    caratula: '',
                }
    
            });
            
            setLoadingConfirmar( false );

            setOpenConfirmar( false );

        }, 1000);  

    }

    const handleActualizarTipoAmparo = async () => {

        setLoadingSaveTipoAmparo( true );

        await ActualizarAmparoTipoAmparo({ idAmparo: amparo.idAmparo, idTipoAmparo: tipoAmparo }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                       
                        if( success ){

                            setTimeout(() => {

                                setLoadingSaveTipoAmparo( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                dispatch({
                                    type: types.actualizarAmparoAcuerdo,
                                    payload: {
                                        ...amparo,
                                        idTipoAmparo: tipoAmparo,
                                    }
                                });

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingSaveTipoAmparo( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingSaveTipoAmparo( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingSaveTipoAmparo( false );
                }

            }

        });     

    }

    const handleActualizarAutoridadAmparo = async () => {

        setLoadingSaveAutoridadAmparo( true );

        await ActualizarAmparoAutoridadAmparo({ idAmparo: amparo.idAmparo, idAutoridadAmparo: autoridadAmparo }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                       
                        if( success ){

                            setTimeout(() => {

                                setLoadingSaveAutoridadAmparo( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                dispatch({
                                    type: types.actualizarAmparoAcuerdo,
                                    payload: {
                                        ...amparo,
                                        idAutoridadAmparo: autoridadAmparo,
                                    }
                                });

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingSaveAutoridadAmparo( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingSaveAutoridadAmparo( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingSaveAutoridadAmparo( false );
                }

            }

        });     

    }

    const handleActualizarNumeroOrigen = async () => {

        setLoadingSaveNumeroOrigen( true );

        await ActualizarAmparoNumeroOrigen({ idAmparo: amparo.idAmparo, numeroAmparoOrigen: numeroAmparoOrigen }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                       
                        if( success ){

                            setTimeout(() => {

                                setLoadingSaveNumeroOrigen( false );
                                setNoReferencia( 'AMPARO ' + numeroAmparoOrigen );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                dispatch({
                                    type: types.actualizarAmparoAcuerdo,
                                    payload: {
                                        ...amparo,
                                        numeroAmparoOrigen: numeroAmparoOrigen,
                                    }
                                });

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingSaveNumeroOrigen( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingSaveNumeroOrigen( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingSaveNumeroOrigen( false );
                }

            }

        });     

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTiposAmparos( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoAmparoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerAutoridadesAmparo( ).then( response => {

                if( response ){

                    if( response.data ){
                        setAutoridadAmparoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        if( amparo && tipoAmparoArray.length > 0 ){
            setTipoAmparo( amparo.idTipoAmparo ?? 0 );
        }

    }, [ tipoAmparoArray, amparo ]);  

    useEffect(() => {
      
        if( amparo && autoridadAmparoArray.length > 0 ){
            setAutoridadAmparo( amparo.idAutoridadAmparo ?? 0 );
        }

    }, [ autoridadAmparoArray, amparo ]);  

    useEffect(() => {
      
        if( amparo?.numeroAmparoOrigen !== '' ){
            setNumeroAmparoOrigen( amparo?.numeroAmparoOrigen ?? '' );
        }

    }, [ amparo ]);  

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ () => setAlert( { open:false }) }>
                        <Alert onClose={ () => setAlert( { open:false }) } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 3 }} >

                {
                    expedientilloSuspension?.success
                    &&
                    <Grid container sx={{ mt: 1, mb: 1 }}>
                        <Grid item md={2} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de expedientillo de suspensión:
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expedientilloSuspension?.numeroExpedientillo }
                            </Typography>
                        </Grid>
                    </Grid>  
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Número de amparo:
                        </Typography>
                    </Grid>
                    <Grid item md={10} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { amparo?.numeroAmparo }
                        </Typography>
                    </Grid>
                </Grid>  

                {
                    amparo?.numeroAmparoOrigen
                    ?
                        
                        (( acuerdo.idAmparo !== 0 && amparo.idTipoAmparo === 2 && edit ) || ( acuerdo.idAmparo !== 0 && amparo.idTipoAmparo === 1 && acuerdo.idPromocion !== 0 && edit ))
                        ?

                            <Grid container sx={{ mt: 1, mb: 1 }}>

                                <Grid item md={2} xs={4}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Número de amparo de origen:
                                    </Typography>
                                </Grid>

                                <Grid item xs={10}>      

                                    <Grid container spacing={3}>   

                                        <Grid item xs={10}>

                                            <TextField                              
                                                label="Número de amparo de origen"
                                                name="numeroAmparoOrigen"       
                                                autoComplete='off'                      
                                                value={ numeroAmparoOrigen }    
                                                onChange={ ( e ) => { setNumeroAmparoOrigen( e.target.value ); } }                                                     
                                                fullWidth       
                                            />    

                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton 
                                                onClick={ handleActualizarNumeroOrigen }
                                                color='primary'    
                                                disabled={ numeroAmparoOrigen === '' }                                    
                                            >
                                                {
                                                    loadingSaveNumeroOrigen
                                                    ?
                                                        <CircularProgress size={20} />
                                                    :
                                                        <SaveIcon />
                                                }
                                            </IconButton>                                        
                                        </Grid>                                            
                                    
                                    </Grid>   

                                </Grid>

                            </Grid>                        
                        :
                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item md={2} xs={4}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Número de amparo de origen:
                                    </Typography>
                                </Grid>
                                <Grid item md={10} xs={8} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { amparo?.numeroAmparoOrigen }
                                    </Typography>
                                </Grid>
                            </Grid>  
                    : null  
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Tipo de amparo:
                        </Typography>
                    </Grid>

                    <Grid item md={10} xs={8} >
                        {
                            ( acuerdo.idAmparo !== 0 && acuerdo.idPromocion === 0 && amparo?.idEstatus === 0 && edit )
                            ?
                                <Grid container spacing={3}>

                                    <Grid item xs={10}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoAmparo-label">Tipo de amparo</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoAmparo-label"
                                                id="select-tipoAmparo"                                            
                                                label="Tipo de amparo"
                                                name="tipoAmparo"                                        
                                                value={ tipoAmparo }
                                                onChange={ ( e ) => setTipoAmparo( e.target.value ) }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                {                                                    
                                                    tipoAmparoArray
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoAmparo } value={ element.idTipoAmparo }>{ element.descripcion }</MenuItem>                        
                                                    ))
                                                }
                                            </Select>
                        
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <IconButton 
                                            onClick={ handleActualizarTipoAmparo }
                                            color='primary'
                                            disabled={ tipoAmparo === amparo.idTipoAmparo }
                                            >
                                            {
                                                loadingSaveTipoAmparo
                                                ?
                                                    <CircularProgress size={20} />
                                                :
                                                    <SaveIcon />
                                            }
                                        </IconButton>                                        
                                    </Grid>

                                </Grid>
                            :
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                    { amparo?.tipoAmparo }
                                </Typography>
                        }                        
                    </Grid>
                    
                </Grid>  

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Autoridad del amparo:
                        </Typography>
                    </Grid>
                    <Grid item md={10} xs={8} >

                    {
                            ( acuerdo.idAmparo !== 0 && acuerdo.idPromocion === 0 && amparo?.idEstatus === 0 && edit )
                            ?
                                <Grid container spacing={3}>

                                    <Grid item xs={10}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-autoridadAmparo-label">Autoridad del amparo</InputLabel>
                                        
                                            <Select
                                                labelId="select-autoridadAmparo-label"
                                                id="select-autoridadAmparo"                                            
                                                label="Autoridad del amparo"
                                                name="autoridadAmparo"                                        
                                                value={ autoridadAmparo }
                                                onChange={ ( e ) => setAutoridadAmparo( e.target.value ) }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                {                                                    
                                                    autoridadAmparoArray
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idAutoridadAmparo } value={ element.idAutoridadAmparo }>{ element.descripcion }</MenuItem>                        
                                                    ))
                                                }
                                            </Select>
                        
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <IconButton 
                                            onClick={ handleActualizarAutoridadAmparo }
                                            color='primary'
                                            disabled={ autoridadAmparo === amparo.idAutoridadAmparo }
                                            >
                                            {
                                                loadingSaveAutoridadAmparo
                                                ?
                                                    <CircularProgress size={20} />
                                                :
                                                    <SaveIcon />
                                            }
                                        </IconButton>                                        
                                    </Grid>

                                </Grid>
                            :
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                    { amparo?.autoridadAmparo }
                                </Typography>
                        }                           
                    </Grid>
                </Grid>  

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Parte(s):
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={6} >
                        <List disablePadding >
                            {
                                amparo?.partes?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                    
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                            { element.parte } <strong>({ element.tipoParte })</strong>
                                        </Typography>                                
                                    </ListItem>   
                                ))
                            }                         
                        </List>
                    </Grid>
                    <Grid item md={2} xs={2} sx={{ textAlign: 'right' }} >

                        {
                            edit && acuerdo.idPromocion === 0
                            &&
                            <IconButton
                                color='primary'
                                onClick={ () => { setOpen( true ); } }
                            >
                                <EditIcon fontSize='small' />
                            </IconButton>
                        }

                    </Grid>
                </Grid>   

                {
                    tribunal
                    &&
                        <Grid container sx={{ mt: 3, mb: 1 }}>
                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >  

                            {
                                amparo?.caratula === ""
                                ?
                                <LoadingButton 
                                    variant="contained" 
                                    startIcon={ <FileCopyIcon /> } 
                                    onClick={ handleOpenCaratula } 
                                    loading={ loading }
                                >
                                    Generar carátula
                                </LoadingButton>
                            :
                                <>
                                    <Button 
                                        variant="contained" 
                                        startIcon={ <PrintIcon /> } 
                                        onClick={ () => { setOpenCaratula( true ); } } 
                                    >
                                        Visualizar carátula
                                    </Button>    

                                    {
                                        edit && acuerdo.idPromocion === 0
                                        && 
                                        <IconButton
                                            color='primary'
                                            onClick={ () => { setOpenConfirmar( true ); } }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                        
                                </>                   
                            }     

                            </Grid>
                        </Grid>   
                }
               
                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Amparo escaneado:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>

                        {
                            acuerdo.amparoDigitalizado
                            ?                        
                            <iframe 
                                title='iframe amparo'
                                style={{ width: '100%', height: '400px' }} 
                                src={ acuerdo.amparoDigitalizado }                                
                            />
                            :
                            null
                        }

                    </Grid>
                </Grid>                    

            </Box>

            <Dialog open={ openCaratula } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               amparo.caratula
                               ?
                               <iframe title='caratula' src={ amparo.caratula } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró la carátula
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCaratula( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <Dialog open={ openConfirmar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar la carátula?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmar( false ); } }> Cancelar </Button>
                    <LoadingButton onClick={ eliminarCaratula } variant='contained' loading={ loadingConfirmar } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <ModalEditarPartes
                Id={ amparo?.idAmparo ?? 0 }
                open={ open }
                setOpen={ setOpen }            
            />

        </>
    )
}
