import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,    
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { types } from '../types/types';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { AuthRouter } from './AuthRouter';
import { SajiScreen } from '../components/SajiScreen';
import { NotFoundScreen } from '../components/auth/NotFoundScreen';

import { UsersScreen } from '../components/admin/users/UsersScreen';
import { UserDetailScreen } from '../components/admin/users/UserDetailScreen';

import { ProfileScreen } from '../components/profile/ProfileScreen';

import { TemplateScreen } from '../components/admin/templates/TemplateScreen';
import { NewTemplateScreen } from '../components/admin/templates/NewTemplateScreen';

import { ApiKeyScreen } from '../components/admin/apikey/ApikeyScreen';

// Importaciones de consultas

import { ExpedienteVirtualScreen } from '../components/consultas/ExpedienteVirtualScreen';
import { AudienciasScreen } from '../components/consultas/AudienciasScreen';
import { TramitesRecibidosScreen } from '../components/consultas/TramitesRecibidosScreen';
import { TramitesDependenciasScreen } from '../components/consultas/TramitesDependenciasScreen';
import { AcuerdosPendientesScreen } from '../components/consultas/AcuerdosPendientesScreen';
import { OficiosScreen } from '../components/consultas/OficiosScreen';
import { MisExpedientesScreen } from '../components/consultas/MisExpedientesScreen';
import { AgendaAudienciasJuezScreen } from '../components/consultas/AgendaAudienciasJuezScreen';
import { ExpedientesAutorizadosScreen } from '../components/consultas/ExpedientesAutorizadosScreen';
import { ExpedientesEtapaProcesalScreen } from "../components/consultas/ExpedientesEtapaProcesalScreen";
import { ReasignacionNotificacionesScreen } from '../components/consultas/ReasignacionNotificacionesScreen';

// ---------------------------------------------------------------------------------------

import { AgendarAudienciaScreen } from '../components/audiencias/AgendarAudienciaScreen';
import { CelebrarAudienciaScreen } from '../components/audiencias/CelebrarAudienciaScreen';
import { ElaborarActaAudienciaScreen } from '../components/audiencias/ElaborarActaAudienciaScreen';
import { RevisarActaAudienciaScreen } from '../components/audiencias/RevisarActaAudienciaScreen';
import { ValidarActaAudienciaScreen } from '../components/audiencias/ValidarActaAudienciaScreen';
import { AutorizarActaAudienciaScreen } from '../components/audiencias/AutorizarActaAudienciaScreen';
import { EscanearAnexosScreen } from '../components/audiencias/EscanearAnexosScreen';
import { VisualizarActaAudienciaScreen } from '../components/audiencias/VisualizarActaAudienciaScreen';

// Importaciones de catálogos

import { CatalogsScreen } from '../components/admin/catalogs/CatalogsScreen';

import { AsentamientoScreen } from '../components/admin/catalogs/AsentamientoScreen';
import { CiudadScreen } from '../components/admin/catalogs/CiudadScreen';
import { EstadoScreen } from '../components/admin/catalogs/EstadoScreen';
import { EstatusScreen } from '../components/admin/catalogs/EstatusScreen';
import { MunicipioScreen } from '../components/admin/catalogs/MunicipioScreen';
import { NacionalidadScreen } from '../components/admin/catalogs/NacionalidadScreen';
import { PaisScreen } from '../components/admin/catalogs/PaisScreen';
import { PonenciaScreen } from '../components/admin/catalogs/PonenciaScreen';
import { PuestoScreen } from '../components/admin/catalogs/PuestoScreen';
import { RolScreen } from '../components/admin/catalogs/RolScreen';
import { SexoScreen } from '../components/admin/catalogs/SexoScreen';
import { TipoAsentamientoScreen } from '../components/admin/catalogs/TipoAsentamientoScreen';
import { TipoDomicilioScreen } from '../components/admin/catalogs/TipoDomicilioScreen';
import { TipoPersonaScreen } from '../components/admin/catalogs/TipoPersonaScreen';
import { TipoSesionScreen } from '../components/admin/catalogs/TipoSesionScreen';
import { TipoUsuarioScreen } from '../components/admin/catalogs/TipoUsuarioScreen';
import { ZonaScreen } from '../components/admin/catalogs/ZonaScreen';

import { SentidoSentenciaScreen } from '../components/admin/catalogs/SentidoSentenciaScreen';
import { SubTipoAcuerdoScreen } from '../components/admin/catalogs/SubTipoAcuerdoScreen';
import { TipoAcuerdoScreen } from '../components/admin/catalogs/TipoAcuerdoScreen';
import { TipoAmparoScreen } from '../components/admin/catalogs/TipoAmparoScreen';
import { TipoAnexoScreen } from '../components/admin/catalogs/TipoAnexoScreen';
import { TipoAudienciaScreen } from '../components/admin/catalogs/TipoAudienciaScreen';
import { TipoCalificacionScreen } from '../components/admin/catalogs/TipoCalificacionScreen';
import { TipoCausaDiferimientoScreen } from '../components/admin/catalogs/TipoCausaDiferimientoScreen';
import { TipoConsecutivoScreen } from '../components/admin/catalogs/TipoConsecutivoScreen';
import { TipoConvenioScreen } from '../components/admin/catalogs/TipoConvenioScreen';
import { TipoExhortoScreen } from '../components/admin/catalogs/TipoExhortoScreen';

import { AutoridadScreen } from '../components/admin/catalogs/AutoridadScreen';
import { AutoridadAmparoScreen } from '../components/admin/catalogs/AutoridadAmparoScreen';
import { AutoridadExhortoScreen } from '../components/admin/catalogs/AutoridadExhortoScreen';
import { ClaseDocumentoScreen } from '../components/admin/catalogs/ClaseDocumentoScreen';
import { ClaseExhortoScreen } from '../components/admin/catalogs/ClaseExhortoScreen';
import { DependenciaScreen } from '../components/admin/catalogs/DependenciaScreen';
import { EtapaScreen } from '../components/admin/catalogs/EtapaScreen';
import { FormaNotificacionScreen } from '../components/admin/catalogs/FormaNotificacionScreen';
import { ObjetoExhortoScreen } from '../components/admin/catalogs/ObjetoExhortoScreen';
import { SentidoAmparoScreen } from '../components/admin/catalogs/SentidoAmparoScreen';
import { TipoDependenciaScreen } from '../components/admin/catalogs/TipoDependenciaScreen';

import { TipoFormatoScreen } from '../components/admin/catalogs/TipoFormatoScreen';
import { TipoIdentificacionScreen } from '../components/admin/catalogs/TipoIdentificacionScreen';
import { TipoIrregularScreen } from '../components/admin/catalogs/TipoIrregularScreen';
import { TipoJuicioScreen } from '../components/admin/catalogs/TipoJuicioScreen';
import { TipoMovimientoScreen } from '../components/admin/catalogs/TipoMovimientoScreen';
import { TipoNoCelebracionScreen } from '../components/admin/catalogs/TipoNoCelebracionScreen';
import { TipoNotificacionScreen } from '../components/admin/catalogs/TipoNotificacionScreen';
import { TipoParteScreen } from '../components/admin/catalogs/TipoParteScreen';
import { TipoPrestacionScreen } from '../components/admin/catalogs/TipoPrestacionScreen';
import { TipoPromocionScreen } from '../components/admin/catalogs/TipoPromocionScreen';
import { TipoTramiteScreen } from '../components/admin/catalogs/TipoTramiteScreen';

import { ProcesoScreen } from '../components/admin/catalogs/ProcesoScreen';
import { TipoDocumentoFirmaScreen } from '../components/admin/catalogs/TipoDocumentoFirmaScreen';
import { TipoOperacionFirmaScreen } from '../components/admin/catalogs/TipoOperacionFirmaScreen';
import { TipoOficioScreen } from '../components/admin/catalogs/TipoOficioScreen';
import { AutoridadExhortoLibradoScreen } from '../components/admin/catalogs/AutoridadExhortoLibradoScreen';
import { TipoPrestacionJuicioScreen } from '../components/admin/catalogs/TipoPrestacionJuicioScreen';
import { TipoAcreditadoScreen } from '../components/admin/catalogs/TipoAcreditadoScreen';

import { TipoApelacionScreen } from '../components/admin/catalogs/TipoApelacionScreen';
import { TipoRecurrenteScreen } from '../components/admin/catalogs/TipoRecurrenteScreen';
import { TipoResolucionScreen } from '../components/admin/catalogs/TipoResolucionScreen';
import { TipoReservadoScreen } from '../components/admin/catalogs/TipoReservadoScreen';
import { AutoridadResolutoriaScreen } from '../components/admin/catalogs/AutoridadResolutoriaScreen';
import { SentidoFalloScreen } from '../components/admin/catalogs/SentidoFalloScreen';

// -----------------------------------------------------------------------------------------------------

import { LibroRegistroScreen } from '../components/librosElectronicos/LibroRegistroScreen';
import { LibroRegistroValoresScreen } from '../components/librosElectronicos/LibroRegistroValoresScreen';
import { LibroAuxiliarScreen } from '../components/librosElectronicos/LibroAuxiliarScreen';
import { LibroAmparoScreen } from '../components/librosElectronicos/LibroAmparoScreen';
import { LibroExhortoLibradoScreen } from '../components/librosElectronicos/LibroExhortoLibradoScreen';
import { LibroExhortoRecibidoScreen } from '../components/librosElectronicos/LibroExhortoRecibidoScreen';
import { LibroActuarioScreen } from '../components/librosElectronicos/LibroActuarioScreen';
import { LibroExpedientesConcluidosScreen } from '../components/librosElectronicos/LibroExpedientesConcluidosScreen';
import { LibroSentenciaDefinitivaScreen } from '../components/librosElectronicos/LibroSentenciaDefinitivaScreen';
import { LibroPromocionesRecibidasScreen } from '../components/librosElectronicos/LibroPromocionesRecibidasScreen';
import { LibroExpedientesFacilitadosConsultaScreen } from '../components/librosElectronicos/LibroExpedientesFacilitadosConsultaScreen';
import { LibroIndiceAlfabeticoScreen } from '../components/librosElectronicos/LibroIndiceAlfabeticoScreen';
import { LibroMedioImpugnacionScreen } from '../components/librosElectronicos/LibroMedioImpugnacionScreen';

// -----------------------------------------------------------------------------------------------------

import { DemandaInicialScreen } from '../components/recepcion/demandaInicial/DemandaInicialScreen';
import { PromocionScreen } from '../components/recepcion/promocion/PromocionScreen';
import { ExhortoRecibidoScreen } from '../components/recepcion/exhortoRecibido/ExhortoRecibidoScreen';
import { AmparoScreen } from '../components/recepcion/amparo/AmparoScreen';

import { ElaborarAcuerdoScreen } from '../components/tribunal/acuerdo/ElaborarAcuerdoScreen';
import { RevisarAcuerdoScreen } from '../components/tribunal/acuerdo/RevisarAcuerdoScreen';
import { ValidarAcuerdoScreen } from '../components/tribunal/acuerdo/ValidarAcuerdoScreen';
import { AutorizarAcuerdoScreen } from '../components/tribunal/acuerdo/AutorizarAcuerdoScreen';
import { VisualizarAcuerdoScreen } from '../components/tribunal/acuerdo/VisualizarAcuerdoScreen';
import { FirmarAcuerdoScreen } from '../components/tribunal/acuerdo/FirmarAcuerdoScreen';

import { GenerarNotificacionScreen } from '../components/notificaciones/GenerarNotificacionScreen';
import { SubirRazonamientoScreen } from '../components/notificaciones/SubirRazonamientoScreen';
import { VisualizarNotificacionScreen } from '../components/notificaciones/VisualizarNotificaciónScreen';
import { GenerarListadoEnvioScreen } from '../components/notificaciones/GenerarListadoEnvioScreen';
import { ConsultarListadoEnvioScreen } from '../components/notificaciones/ConsultarListadoEnvioScreen';

import { ElaborarProyectoSentenciaScreen } from '../components/sentencias/proyectoSentencia/ElaborarProyectoSentenciaScreen';
import { AutorizarProyectoSentenciaScreen } from '../components/sentencias/proyectoSentencia/AutorizarProyectoSentenciaScreen';
import { VisualizarProyectoSentenciaScreen } from '../components/sentencias/proyectoSentencia/VisualizarProyectoSentenciaScreen';
import { RevisarProyectoSentenciaObservacionesScreen } from '../components/sentencias/proyectoSentencia/RevisarProyectoSentenciaObservacionesScreen';

import { ElaborarSentenciaScreen } from '../components/sentencias/sentencia/ElaborarSentenciaScreen';
import { AutorizarSentenciaScreen } from '../components/sentencias/sentencia/AutorizarSentenciaScreen';
import { FirmarSentenciaScreen } from '../components/sentencias/sentencia/FirmarSentenciaScreen';
import { VisualizarSentenciaScreen } from '../components/sentencias/sentencia/VisualizarSentenciaScreen';

import { ElaborarSentenciaPublicaScreen } from '../components/sentencias/sentenciaPublica/ElaborarSentenciaPublicaScreen';
import { AutorizarSentenciaPublicaScreen } from '../components/sentencias/sentenciaPublica/AutorizarSentenciaPublicaScreen';
import { VisualizarSentenciaPublicaScreen } from '../components/sentencias/sentenciaPublica/VisualizarSentenciaPublicaScreen';

import { ElaborarSentenciaCortaScreen } from '../components/sentencias/sentenciaCorta/ElaborarSentenciaCortaScreen';
import { AutorizarSentenciaCortaScreen } from '../components/sentencias/sentenciaCorta/AutorizarSentenciaCortaScreen';
import { VisualizarSentenciaCortaScreen } from '../components/sentencias/sentenciaCorta/VisualizarSentenciaCortaScreen';

import { GenerarListaAcuerdosScreen } from '../components/tribunal/listaAcuerdos/GenerarListaAcuerdosScreen';
import { ConsultarListaAcuerdosScreen } from '../components/tribunal/listaAcuerdos/ConsultarListaAcuerdosScreen';

import { IrregularScreen } from '../components/tribunal/irregular/IrregularScreen';

import { ElaborarOficioScreen } from '../components/tribunal/oficio/ElaborarOficioScreen';
import { AutorizarOficioScreen } from '../components/tribunal/oficio/AutorizarOficioScreen';
import { VisualizarOficioScreen } from '../components/tribunal/oficio/VisualizarOficioScreen';

import { ElaborarExhortoLibradoScreen } from '../components/tribunal/exhortoLibrado/ElaborarExhortoLibradoScreen';
import { AutorizarExhortoLibradoScreen } from '../components/tribunal/exhortoLibrado/AutorizarExhortoLibradoScreen';
import { VisualizarExhortoLibradoScreen } from '../components/tribunal/exhortoLibrado/VisualizarExhortoLibradoScreen';

import { NuevoRegistroValorScreen } from '../components/tribunal/registroValores/NuevoRegistroValorScreen';
import { EntregaChequeScreen } from '../components/tribunal/registroValores/EntregaChequeScreen';

import { RegistrarPrestamoScreen } from '../components/tribunal/prestamoExpediente/RegistrarPrestamoScreen';

import { EstadisticaScreen } from '../components/reportes/EstadisticaScreen';

import { ConsecutivosScreen } from '../components/admin/consecutivo/ConsecutivosScreen';
import { MateriaScreen } from '../components/admin/catalogs/MaterialScreen';
import { DistritoJudicialScreen } from '../components/admin/catalogs/DistritoJudicialScreen';
import { SubEtapaScreen } from '../components/admin/catalogs/SubEtapaScreen';

import { ActividadesScreen } from '../components/admin/actividades/ActividadesScreen';
import { AdministrarDocumentosScreen } from '../components/admin/administrarDocumentos/AdministrarDocumentosScreen';
import { ExhortoLibradoScreen } from '../components/consultas/ExhortoLibradoScreen';
import { NotificacionesScreen } from '../components/consultas/NotificacionesScreen';
import { CierreInstruccionScreen } from '../components/consultas/CierreInstruccionScreen';

import { GenerarListaEstradosScreen } from '../components/tribunal/listaEstrados/GenerarListaEstradosScreen';
import { ConsultarListaEstradosScreen } from '../components/tribunal/listaEstrados/ConsultarListaEstradosScreen';
import { AgendaAudienciasSecretarioScreen } from '../components/consultas/AgendaAudienciasSecretarioScreen';

import { ElaborarConvocatoriaScreen } from '../components/tribunal/convocatoria/ElaborarConvocatoriaScreen';
import { AutorizarConvocatoriaScreen } from '../components/tribunal/convocatoria/AutorizarConvocatoriaScreen';
import { VisualizarConvocatoriaScreen } from '../components/tribunal/convocatoria/VisualizarConvocatoriaScreen';
import { ValidarConvocatoriaScreen } from '../components/tribunal/convocatoria/ValidarConvocatoriaScreen';

import { GenerarCaratulaScreen } from '../components/tribunal/caratula/GenerarCaratulaScreen';
import { ConvenioFueraJuicioScreen } from '../components/consultas/ConvenioFueraJuicioScreen';

import { GestionarExpedienteAnteriorScreen } from '../components/tribunal/expediente/GestionarExpedienteAnteriorScreen';
import { GestionarExhortoAnteriorScreen } from '../components/tribunal/exhortoRecibido/GestionarExhortoAnteriorScreen';
import { GestionarRegistroMasivoScreen } from '../components/tribunal/expediente/GestionarRegistroMasivoScreen';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const { token } = useSelector(state => state.auth);

    const data = window.localStorage.getItem("data");

    useEffect(() => {
        
        if( data ){
            dispatch({
                type: types.login,
                payload: JSON.parse(data)
            });
        } 
        else{            
            localStorage.removeItem('data');
            localStorage.removeItem('lastPath');
        }      

    }, [ data, dispatch ]); 

    return (
        <Router>
            <div>
                <Switch>

                    <PublicRoute
                        isAuthenticated={ Boolean(token) }
                        path="/Auth/"
                        component={ AuthRouter }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/"
                        component={ SajiScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/User/Profile/"
                        component={ ProfileScreen }
                    />


                    {/*                  Rutas de Administración:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Users/"
                        component={ UsersScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/User/:id"
                        component={ UserDetailScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Templates/"
                        component={ TemplateScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/NewTemplate/"
                        component={ NewTemplateScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Consecutivos/"
                        component={ ConsecutivosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Actividades/"
                        component={ ActividadesScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/AdministrarDocumentos/"
                        component={ AdministrarDocumentosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Apikeys/"
                        component={ ApiKeyScreen }
                    />

                    {/*                  Rutas de Libros electrónicos:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroRegistro/"
                        component={ LibroRegistroScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroRegistroValores/"
                        component={ LibroRegistroValoresScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroAuxiliar/"
                        component={ LibroAuxiliarScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroAmparo/"
                        component={ LibroAmparoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroExhortoLibrado/"
                        component={ LibroExhortoLibradoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroExhortoRecibido/"
                        component={ LibroExhortoRecibidoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroActuario/"
                        component={ LibroActuarioScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroExpedientesConcluidos/"
                        component={ LibroExpedientesConcluidosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroSentenciaDefinitiva/"
                        component={ LibroSentenciaDefinitivaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroPromocionesRecibidas/"
                        component={ LibroPromocionesRecibidasScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroExpedientesfacilitadosConsulta/"
                        component={ LibroExpedientesFacilitadosConsultaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroIndiceAlfabetico/"
                        component={ LibroIndiceAlfabeticoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Libro/LibroMedioImpugnacion/"
                        component={ LibroMedioImpugnacionScreen }
                    />


                    {/*                  Rutas de Consultas:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/Audiencias/"
                        component={ AudienciasScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/TramitesRecibidos/"
                        component={ TramitesRecibidosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ExpedienteVirtual/"
                        component={ ExpedienteVirtualScreen }
                    />     

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/TramitesDependencias/"
                        component={ TramitesDependenciasScreen }
                    />       

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/AcuerdosPendientes/"
                        component={ AcuerdosPendientesScreen }
                    />      

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/Oficios/"
                        component={ OficiosScreen }
                    />   

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ExhortoLibrado/"
                        component={ ExhortoLibradoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/Notificaciones/"
                        component={ NotificacionesScreen }
                    /> 
                                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/CierreInstruccion/"
                        component={ CierreInstruccionScreen }
                    />       

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/MisExpedientes/"
                        component={ MisExpedientesScreen }
                    />  

                     <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/AgendaAudienciasJuez/"
                        component={ AgendaAudienciasJuezScreen }
                    />    

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ExpedientesAutorizados/"
                        component={ ExpedientesAutorizadosScreen }
                    />       
                          
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/AgendaAudienciasSecretario/"
                        component={ AgendaAudienciasSecretarioScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ExpedientesEtapaProcesal/"
                        component={ ExpedientesEtapaProcesalScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ReasignacionNotificaciones/"
                        component={ ReasignacionNotificacionesScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Consulta/ConveniosFueraJuicio"
                        component={ ConvenioFueraJuicioScreen }
                    />
                          
                    {/*                  Rutas de Catálogos:                       */}
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalogs/"
                        component={ CatalogsScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/AutoridadAmparo"
                        component={ AutoridadAmparoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/AutoridadExhorto"
                        component={ AutoridadExhortoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Autoridad"
                        component={ AutoridadScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/ClaseDocumento"
                        component={ ClaseDocumentoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/ClaseExhorto"
                        component={ ClaseExhortoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Dependencia"
                        component={ DependenciaScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Etapa"
                        component={ EtapaScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/FormaNotificacion"
                        component={ FormaNotificacionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/ObjetoExhorto"
                        component={ ObjetoExhortoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/SentidoAmparo"
                        component={ SentidoAmparoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/SentidoSentencia"
                        component={ SentidoSentenciaScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/SubTipoAcuerdo"
                        component={ SubTipoAcuerdoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAcuerdo"
                        component={ TipoAcuerdoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAmparo"
                        component={ TipoAmparoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAnexo"
                        component={ TipoAnexoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAudiencia"
                        component={ TipoAudienciaScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoCalificacion"
                        component={ TipoCalificacionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoCausaDiferimiento"
                        component={ TipoCausaDiferimientoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoConsecutivo"
                        component={ TipoConsecutivoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoConvenio"
                        component={ TipoConvenioScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoDependencia"
                        component={ TipoDependenciaScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoExhorto"
                        component={ TipoExhortoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoFormato"
                        component={ TipoFormatoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoIdentificacion"
                        component={ TipoIdentificacionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoIrregular"
                        component={ TipoIrregularScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoJuicio"
                        component={ TipoJuicioScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoMovimiento"
                        component={ TipoMovimientoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoNoCelebracion"
                        component={ TipoNoCelebracionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoNotificacion"
                        component={ TipoNotificacionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoParte"
                        component={ TipoParteScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoPrestacion"
                        component={ TipoPrestacionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoPromocion"
                        component={ TipoPromocionScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoTramite"
                        component={ TipoTramiteScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Asentamiento"
                        component={ AsentamientoScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Ciudad"
                        component={ CiudadScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Estado"
                        component={ EstadoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Estatus"
                        component={ EstatusScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Nacionalidad"
                        component={ NacionalidadScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Municipio"
                        component={ MunicipioScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Pais"
                        component={ PaisScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Ponencia"
                        component={ PonenciaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Puesto"
                        component={ PuestoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Zona"
                        component={ ZonaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoUsuario"
                        component={ TipoUsuarioScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoSesion"
                        component={ TipoSesionScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoPersona"
                        component={ TipoPersonaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoDomicilio"
                        component={ TipoDomicilioScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAsentamiento"
                        component={ TipoAsentamientoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Sexo"
                        component={ SexoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Rol"
                        component={ RolScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Materia"
                        component={ MateriaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/DistritoJudicial"
                        component={ DistritoJudicialScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/SubEtapa"
                        component={ SubEtapaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/Proceso"
                        component={ ProcesoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoDocumento"
                        component={ TipoDocumentoFirmaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoOperacion"
                        component={ TipoOperacionFirmaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoOficio"
                        component={ TipoOficioScreen }
                    /> 

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/AutoridadExhortoLibrado"
                        component={ AutoridadExhortoLibradoScreen }
                    />   

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoPrestacionJuicio"
                        component={ TipoPrestacionJuicioScreen }
                    />  
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoAcreditado"
                        component={ TipoAcreditadoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoApelacion"
                        component={ TipoApelacionScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoRecurrente"
                        component={ TipoRecurrenteScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoResolucion"
                        component={ TipoResolucionScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/TipoReservado"
                        component={ TipoReservadoScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/AutoridadResolutoria"
                        component={ AutoridadResolutoriaScreen }
                    />  

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Admin/Catalog/SentidoFallo"
                        component={ SentidoFalloScreen }
                    />  

                    {/*                  Ruta proceso de Recepción:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Recepcion/DemandaInicial/:Id"
                        component={ DemandaInicialScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Recepcion/Promocion/:Id"
                        component={ PromocionScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Recepcion/ExhortoRecibido/:Id"
                        component={ ExhortoRecibidoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Recepcion/Amparo/:Id"
                        component={ AmparoScreen }
                    />


                    {/*                  Ruta proceso de acuerdo:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/ElaborarAcuerdo/:Id"
                        component={ ElaborarAcuerdoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/RevisarAcuerdo/:Id"
                        component={ RevisarAcuerdoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/ValidarAcuerdo/:Id"
                        component={ ValidarAcuerdoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/AutorizarAcuerdo/:Id"
                        component={ AutorizarAcuerdoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/VisualizarAcuerdo/:Id"
                        component={ VisualizarAcuerdoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GestionarAcuerdo/FirmarAcuerdo/:Id"
                        component={ FirmarAcuerdoScreen }
                    />


                    {/*                  Ruta proceso de Audiencia:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/AgendarAudiencia/"
                        component={ AgendarAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/CelebrarAudiencia/:Id"
                        component={ CelebrarAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/ElaborarActaAudiencia/:Id"
                        component={ ElaborarActaAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/RevisarActaAudiencia/:Id"
                        component={ RevisarActaAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/ValidarActaAudiencia/:Id"
                        component={ ValidarActaAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/AutorizarActaAudiencia/:Id"
                        component={ AutorizarActaAudienciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/EscanearAnexos/:Id"
                        component={ EscanearAnexosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Audiencia/VisualizarActaAudiencia/:Id"
                        component={ VisualizarActaAudienciaScreen }
                    />

                    {/*                  Ruta proceso de notificacion:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Notificacion/GenerarNotificacion/:Id"
                        component={ GenerarNotificacionScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Notificacion/SubirRazonamiento/:Id"
                        component={ SubirRazonamientoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Notificacion/VisualizarNotificacion/:Id"
                        component={ VisualizarNotificacionScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Notificacion/ListadoEnvio/Generar/:Id"
                        component={ GenerarListadoEnvioScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Notificacion/ListadoEnvio/Consultar/"
                        component={ ConsultarListadoEnvioScreen }
                    />

                    {/*                  Ruta proceso de proyecto de sentencia:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/ElaborarProyecto/:Id"
                        component={ ElaborarProyectoSentenciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/RevisarProyecto/Observaciones/:Id"
                        component={ RevisarProyectoSentenciaObservacionesScreen }
                    />
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/AutorizarProyecto/:Id"
                        component={ AutorizarProyectoSentenciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/VisualizarProyecto/:Id"
                        component={ VisualizarProyectoSentenciaScreen }
                    />

                    {/*                  Ruta proceso de sentencia / laudo:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/ElaborarSentencia/:Id"
                        component={ ElaborarSentenciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/AutorizarSentencia/:Id"
                        component={ AutorizarSentenciaScreen }
                    />

                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/FirmarSentencia/:Id"
                        component={ FirmarSentenciaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/VisualizarSentencia/:Id"
                        component={ VisualizarSentenciaScreen }
                    />

                    {/*                  Ruta proceso de sentencia pública:                       */}

                     <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/ElaborarSentenciaPublica/:Id"
                        component={ ElaborarSentenciaPublicaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/AutorizarSentenciaPublica/:Id"
                        component={ AutorizarSentenciaPublicaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/VisualizarSentenciaPublica/:Id"
                        component={ VisualizarSentenciaPublicaScreen }
                    />

                    {/*                  Ruta proceso de sentencia corta:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/ElaborarSentenciaCorta/:Id"
                        component={ ElaborarSentenciaCortaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/AutorizarSentenciaCorta/:Id"
                        component={ AutorizarSentenciaCortaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Sentencia/VisualizarSentenciaCorta/:Id"
                        component={ VisualizarSentenciaCortaScreen }
                    />

                    {/*                  Ruta proceso de lista de acuerdos:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ListaAcuerdos/Generar/:Id"
                        component={ GenerarListaAcuerdosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ListaAcuerdos/Consultar/"
                        component={ ConsultarListaAcuerdosScreen }
                    />

                    {/*                  Ruta proceso de generar expedientillo irregular:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/GenerarIrregular/:Id"
                        component={ IrregularScreen }
                    />


                    {/*                  Ruta proceso de generación de oficios:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Oficio/ElaborarOficio/:Id"
                        component={ ElaborarOficioScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Oficio/AutorizarOficio/:Id"
                        component={ AutorizarOficioScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Oficio/VisualizarOficio/:Id"
                        component={ VisualizarOficioScreen }
                    />

                    {/*                  Ruta proceso de generación de exhortos librados:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ExhortoLibrado/ElaborarExhortoLibrado/:Id"
                        component={ ElaborarExhortoLibradoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ExhortoLibrado/AutorizarExhortoLibrado/:Id"
                        component={ AutorizarExhortoLibradoScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ExhortoLibrado/VisualizarExhortoLibrado/:Id"
                        component={ VisualizarExhortoLibradoScreen }
                    />

                    {/*                  Ruta proceso de registro de valores:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/RegistroValor/NuevoRegistro/:Id"
                        component={ NuevoRegistroValorScreen }
                    />
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/RegistroValor/EntregaCheque/:Id"
                        component={ EntregaChequeScreen }
                    />

                    {/*                  Ruta proceso de registro de expedientes facilitados para consulta:                       */}
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/PrestamosExpediente/RegistrarPrestamo/:Id"
                        component={ RegistrarPrestamoScreen }
                    />

                    {/*                  Ruta para el modulo de estadistica:                       */}
                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Reportes/Estadistica/"
                        component={ EstadisticaScreen }
                    />

                    {/*                  Ruta proceso de lista de estrados:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ListaEstrados/Generar/:Id"
                        component={ GenerarListaEstradosScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/ListaEstrados/Consultar/"
                        component={ ConsultarListaEstradosScreen }
                    />

                    
                    {/*                  Ruta proceso de generación de convocatorias:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Convocatoria/ElaborarConvocatoria/:Id"
                        component={ ElaborarConvocatoriaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Convocatoria/ValidarConvocatoria/:Id"
                        component={ ValidarConvocatoriaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Convocatoria/AutorizarConvocatoria/:Id"
                        component={ AutorizarConvocatoriaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Tribunal/Convocatoria/VisualizarConvocatoria/:Id"
                        component={ VisualizarConvocatoriaScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Caratula/GenerarCaratula"
                        component={ GenerarCaratulaScreen }
                    />

                    {/*                  Ruta proceso de expedientes y exhortos anteriores:                       */}

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Expediente/GestionarExpedienteAnterior"
                        component={ GestionarExpedienteAnteriorScreen }
                    />

                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Exhorto/GestionarExhortoAnterior"
                        component={ GestionarExhortoAnteriorScreen }
                    />

                    
                    <PrivateRoute 
                        exact
                        isAuthenticated={ Boolean(token) }
                        path="/Expediente/GestionarRegistroMasivo"
                        component={ GestionarRegistroMasivoScreen }
                    />

                    <Route path="*" exact component={ NotFoundScreen } /> 

                </Switch>
            </div>
        </Router>
    )
}
