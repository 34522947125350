import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { withRouter } from 'react-router';

import { Alert, AlertTitle, Button, Chip, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LaunchIcon from '@mui/icons-material/Launch';
import CommentIcon from '@mui/icons-material/Comment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { types } from '../../types/types';

import { MostrarInbox, ObtenerAsuntosPrioritarios } from '../../actions/inbox/inboxActions';

const InboxScreen = ( props ) => {

    const { firmaElectronica, diasExpiracionFirmaElectronica } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState( [] );
    const [dataTableAsuntos, setDataTableAsuntos] = useState( [] );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage ] = usePages(10);
    const [ pageAsuntos, rowsPerPageAsuntos, handleChangePageAsuntos, handleChangeRowsPerPageAsuntos ] = usePages(10);

    const [openModal, setOpenModal] = useState(false);

    const ProcesoPendiente = ( path ) =>  props.history.push( path ) 

    useEffect(() => {

        dispatch( { type: types.openLoading } );        

        async function Obtener(){
          
            await MostrarInbox( ).then( response => {

                if( response ){

                    if( response.data ){
                        
                        setDataTable( response.data );
                        
                        setTimeout(() => {
            
                            dispatch( { type: types.closeLoading } );
                
                        }, 600);
                    }

                }

            });

        }     
        
        Obtener();        

    }, [ dispatch ]);

    useEffect(() => {    

        async function Obtener(){
          
            await ObtenerAsuntosPrioritarios().then( response => {

                if( response ){

                    if( response.data ){
                        
                        setDataTableAsuntos( response.data );    

                    }

                }

            });

        }     
        
        Obtener();        

    }, [ ]);

    return (
        <Container maxWidth='xl'>

            {
                dataTableAsuntos.length !== 0
                &&
                    <Alert 
                        severity="warning" 
                        color="warning"
                        sx={{ mb: 1 }}
                        action={
                            <Button
                                variant='contained' 
                                size='small'
                                sx={{ fontSize: 12 }}
                                onClick={ () => setOpenModal( true ) }
                            >
                                Ver asuntos
                            </Button>
                        }
                    >
                        <strong>Tramites Prioritarios - (Amparos) </strong>
                    </Alert>
            }

            {
                ( firmaElectronica && diasExpiracionFirmaElectronica <= 10 )
                &&

                    <Alert severity={ diasExpiracionFirmaElectronica <= 0 ? 'error' : 'warning' } sx={{ mb: 2 }}>
                        <AlertTitle sx={{ fontWeight: 'bold' }}>Advertencia.</AlertTitle>
                        {
                            diasExpiracionFirmaElectronica <= 0 
                            ?
                                'La firma electronica se encuentra vencida o expirada.'
                            :
                                diasExpiracionFirmaElectronica === 1
                                ?
                                    'La fecha de vencimiento o expiración de la firma electronica esta proxima queda ' + diasExpiracionFirmaElectronica + ' dia.'                                
                                :                                
                                    'La fecha de vencimiento o expiración de la firma electronica esta proxima quedan ' + diasExpiracionFirmaElectronica + ' dias.'                                
                        }
                    </Alert>
            }

            <Paper sx={{ mt: dataTableAsuntos.length !== 0 ? 2 : 0 }}>
 
                <TableContainer>

                    <Table>

                        <TableHead>

                            <TableRow>

                                <TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'center' }}>N. DE SEGUIMIENTO</TableCell>
                                <TableCell style={{ width: '22%', fontWeight: 'bold' }}>PROCESO</TableCell>
                                <TableCell style={{ width: '28%', fontWeight: 'bold' }}>ACTIVIDAD</TableCell>
                                <TableCell style={{ width: '20%', fontWeight: 'bold' }}>FECHA</TableCell>
                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                <TableCell style={{ width: '3%', textAlign: 'center' }}></TableCell>
                                <TableCell style={{ width: '1%', textAlign: 'center' }}></TableCell>
                                                                        
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {
                                (
                                    rowsPerPage > 0
                                    ?
                                    dataTable.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                    :
                                    dataTable
                                ).map( ( row, index ) => (

                                    <TableRow key={ index } >
                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'Bold' }} > { row.numeroSeguimiento } </TableCell>
                                        
                                        <TableCell> 
                                            <strong style={{ fontSize: 15 }}>{  row.proceso } </strong> <br />
                                            <small style={{ fontSize: 13 }}> { row.tipoTramite && " (" + row.tipoTramite + ")" } </small>
                                        </TableCell>
                                       
                                        <TableCell> 
                                            {
                                                row.expedienteAnterior === true
                                                ?
                                                    <Typography variant='caption' fontSize={14}>
                                                        Se necesita importar la información del trámite
                                                    </Typography>
                                                :
                                                    <Button 
                                                        variant={ "contained"}
                                                        sx={{ textAlign: 'left', textTransform: 'none' }}
                                                        onClick={ () => ProcesoPendiente( row.path ) }       
                                                        startIcon={ <BusinessCenterIcon /> }  
                                                        size='small'
                                                    >
                                                       { row.actividad } 
                                                    </Button>  
                                            }
                                        </TableCell>                                                                            
                                                                               
                                        <TableCell> { row.fecha } </TableCell>
                                        
                                        {
                                            row.noReferencia
                                            ?
                                                <>
                                                    <TableCell> 
                                                        <Typography fontWeight={'bold'} fontSize={13}>{ row.referencia }</Typography>
                                                        <Typography fontSize={11} mt={1}>{ row.noReferencia }</Typography>                                                        
                                                    </TableCell>                                                
                                                </>
                                            :
                                                <TableCell> 
                                                    <Typography fontWeight={'bold'} fontSize={13}>{ row.referencia }</Typography>
                                                </TableCell>
                                        }                                        

                                        <TableCell sx={{ textAlign: 'center' }}>  
                                            {
                                                row?.provieneBuzonElectronico
                                                &&
                                                <Chip
                                                    variant='outlined'                                                                               
                                                    label={ <Typography variant='subtitle2'> { row?.folioBuzonElectronico } </Typography> }
                                                    icon={ <ForwardToInboxIcon fontSize='small' /> }
                                                    size='small'
                                                    sx={{ p: 2 }}
                                                />
                                            }                                                    
                                        </TableCell>

                                        <TableCell>
                                            {
                                                row?.observaciones
                                                &&
                                                    <CommentIcon color='warning' />
                                            }
                                        </TableCell>

                                    </TableRow>

                                ))
                            }

                            {
                                dataTable.length === 0 && 
                                (
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            align="center"
                                            style={{ fontWeight: 'bold', fontSize: 15 }}
                                        >
                                            No se encontraron resultados
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                        </TableBody>

                        <TableFooter>
                            

                            <TableRow>
                           
                                <TableCell colSpan={2}>
                                    <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { dataTable.length } </Typography>
                                </TableCell> 
                           
                                <TablePagination 
                                    colSpan={7}
                                    rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                    count={ dataTable.length }
                                    rowsPerPage={ rowsPerPage }
                                    page={ page }
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }

                                    ActionsComponent={ TablePaginationActions }

                                    labelRowsPerPage="Filas por página"                                            
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                        return '' + from + '-' + to + ' de ' + count
                                        }
                                    }                                                                                       
                                />
                            </TableRow>

                        </TableFooter>

                    </Table>
                    
                </TableContainer>

            </Paper>

            <Dialog open={ openModal } maxWidth='lg' fullWidth={true}>            
            
                <DialogContent>
                    
                    <Grid container spacing={3} style={{ marginTop: '1px' }}>         

                        <Grid item md={12} xs={12} >                           
                           
                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'center' }}>N. DE SEGUIMIENTO</TableCell>                                           
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>PROCESO</TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>ACTIVIDAD</TableCell>
                                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>USUARIO</TableCell>
                                            <TableCell style={{ width: '10%', fontWeight: 'bold' }}>FECHA</TableCell>
                                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                            <TableCell style={{ width: '1%', textAlign: 'center' }}></TableCell>
                                                                                    
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (
                                                rowsPerPage > 0
                                                ?
                                                dataTableAsuntos.slice( pageAsuntos * rowsPerPageAsuntos, pageAsuntos * rowsPerPageAsuntos + rowsPerPageAsuntos )
                                                :
                                                dataTableAsuntos
                                            ).map( ( row, index ) => (

                                                <TableRow key={ index } >
                                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'Bold' }} > { row.numeroSeguimiento } </TableCell>
                                                    
                                                    <TableCell> 
                                                        <strong style={{ fontSize: 15 }}>{  row.proceso } </strong> <br />
                                                        <small style={{ fontSize: 13 }}> { row.tipoTramite && " (" + row.tipoTramite + ")" } </small>
                                                    </TableCell>
                                                    
                                                    <TableCell> 
                                                        { row.actividad }                                                                                            
                                                    </TableCell>
                                                    
                                                    <TableCell> { row.usuario } </TableCell>
                                                    <TableCell> { row.fecha } </TableCell>

                                                    {
                                                        row.noReferencia
                                                        ?
                                                            <>
                                                                <TableCell> 
                                                                    <Typography fontWeight={'bold'} fontSize={13}>{ row.referencia }</Typography>
                                                                    <Typography fontSize={11} mt={1}>{ row.noReferencia }</Typography>                                                        
                                                                </TableCell>                                                
                                                            </>
                                                        :
                                                            <TableCell> 
                                                                <Typography fontWeight={'bold'} fontSize={13}>{ row.referencia }</Typography>
                                                            </TableCell>
                                                    }       
                                             
                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        {                                                           
                                                            row.usuarioActual
                                                            ?
                                                                <IconButton 
                                                                    onClick={ () => { ProcesoPendiente( row.path ); } }                                     
                                                                >
                                                                    <LaunchIcon />
                                                                </IconButton>
                                                            :
                                                                null
                                                        }

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            dataTableAsuntos.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={7}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron resultados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>
                                            <TablePagination 
                                                colSpan={7}
                                                rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                                count={ dataTableAsuntos.length }
                                                rowsPerPage={ rowsPerPageAsuntos }
                                                page={ pageAsuntos }
                                                onPageChange={ handleChangePageAsuntos }
                                                onRowsPerPageChange={ handleChangeRowsPerPageAsuntos }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>           
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenModal( false ) }>Cancelar</Button>
                </DialogActions>

            </Dialog>
            
        </Container>
    )
}


export default withRouter(InboxScreen);