import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Editor } from '@tinymce/tinymce-react';

import AppBarMenu from '../../ui/AppBarMenu';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerSentidosSentencias } from '../../../actions/admin/catalogs/sentidoSentenciaActions';
import { ActualizarEstatus, GuardarSentencia, ObtenerSentenciaDetalle } from '../../../actions/sentencias/sentenciaActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';

import { SentenciaNotificacionScreen } from './SentenciaNotificacionScreen';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';
import { ObtenerSentidosFallo } from '../../../actions/admin/catalogs/sentidoFalloActions';
import { MediosImpugnacionSentencia } from '../../../actions/tribunal/medioImpugnacionActions';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ElaborarSentenciaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const { tribunal, juzgado } = useSelector( state => state.auth );

    const editorRef = useRef(null);

    const [valueTab, setValueTab] = useState(0);

    const [loading, setLoading] = useState( false );
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );   
    const [loadingGuardar, setLoadingGuardar] = useState( false );  

    const [sentencia, setSentencia] = useState( { } );

    const [sentidoSentencia, setSentidoSentencia] = useState( 0 );
    const [sentidoSentenciaAray, setSentidoSentenciaArray] = useState( [ ] );

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [fechaCitacion, setFechaCitacion] = useState( '' );
    const [perspectivaGenero, setPerspectivaGenero] = useState( false );

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [openConfirmacion, setOpenConfirmacion] = useState( false );
    const [activarFinalizar, setActivarFinalizar] = useState( false );
    const [ajaxLoading, setAjaxLoading] = useState( false );

    const [alert, setAlert] = useState( {  } );
    const [errors, setErrors] = useState( { } );

    const [richText, setRichText] = useState( '' ); 

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );
    
    const [partes, setPartes] = useState( [] );

    const [tipoSentencia, setTipoSentencia] = useState( 1 );

    const edit = true;

    const [resuelveRecurso, setResuelveRecurso] = useState( false );   

    const [medioImpugnacionTemp, setMedioImpugnacionTemp] = useState(0);
    const [medioImpugnacion, setMedioImpugnacion] = useState(0);
    const [medioImpugnacionArray, setMedioImpugnacionArray] = useState( [] );

    const [sentidoFallo, setSentidoFallo] = useState(0);
    const [sentidoFalloArray, setSentidoFalloArray] = useState( [] );

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };    

    const handleChangeEditor = () => {

        if( editorRef.current ){
        
            if( editorRef.current.getContent() !== '' ){

                setRichText(editorRef.current.getContent());

            }
        }

    }

    const handleChangePerspectivaGenero = ( value ) => {

        setPerspectivaGenero( value );
    }

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};       
        
        const contenido = editorRef.current.getContent();

        if( sentidoSentencia === 0 ){      
            valid = false;
            errores.sentidoSentencia = 'Debes seleccionar el sentido de la sentencia';
        }  

        if( plantilla === 0 && !sentencia?.proyectoSentencia ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar una plantilla';
        }  

        if( fechaCitacion === '' ){      
            valid = false;
            errores.fechaCitacion = 'Debes seleccionar la fecha de citación';
        }  

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la sentencia';
        }  

        setErrors( errores );
        return valid;
    }

    const handleGuardarSentencia = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {

            idSentencia: Id,
            idSentidoSentencia: sentidoSentencia,
            fechaCitacion: fechaCitacion ? fechaCitacion : null,
            idPlantilla: plantilla,
            perspectivaGenero: perspectivaGenero,
            contenido: contenido,
            resuelveRecurso: resuelveRecurso,
            idMedioImpugnacion: medioImpugnacion,
            idSentidoFallo: sentidoFallo,
        };

        setLoadingGuardar( true );

        await GuardarSentencia( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout( () => {
                        
                        setActivarFinalizar( true );

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  

                }
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }

            }

        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', sentencia.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', sentencia.ponencia ?? "" )
                            .replaceAll('[Numero_Exhorto]', sentencia.numeroExhorto ?? "" )
                            .replaceAll('[Numero_Expediente]', sentencia.numeroExpediente ?? "" )
                            .replaceAll('[Ciudad]', sentencia?.ciudad ?? "" );

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }

    const Finalizar = async () => {     
        
        setLoadingFinalizar( true );

        const params = {
            idSentencia : Id,
            idEstatus: 28,
            resuelveRecurso: resuelveRecurso,
            idMedioImpugnacion: medioImpugnacion,
            idSentidoFallo: sentidoFallo,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }      

                        setLoading( true );                        

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleResuelveRecurso = ( value ) => {

        setMedioImpugnacion( 0 );
        setSentidoFallo( 0 );
        setResuelveRecurso( value );

    }   

    useEffect(() => {      

        async function Obtener(){

            await ObtenerSentidosSentencias().then( response => {

                if( response ){

                    if( response.data ){
                        setSentidoSentenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])
    
    useEffect(() => {      
        
        async function Obtener(){
            
            const params = {
                'idTipoAcuerdo': 5,
                //'idSubTipoAcuerdo': 0,
                'idSubTipoAcuerdo': tipoSentencia === 2 ? 36 : 8,                
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );                        

                    }

                }

            });

        }     
        
        if ( tipoSentencia !== 0 ) {
            Obtener();            
        }

    }, [ tipoSentencia ])

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idSentencia': Id,                
            };

            dispatch({ type: types.openLoading });

            await ObtenerSentenciaDetalle( params ).then( response => {
               
                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setSentencia( response.data );      
                            setSentidoSentencia( response.data.idSentidoSentencia ?? 0 );
                            setPerspectivaGenero( response.data.perspectivaGenero );
                            setFechaCitacion( response.data.fechaCitacion ?? '' );
                            setPlantilla( response.data.idPlantilla ?? 0 );

                            setRichText( response.data.contenido ?? '' );                             
                            setTipoSentencia( response.data.idTipoSentencia ?? 1 );                         
                            setPartes( response.data.partes ?? [] );         
                            
                            if( response?.data?.idTipoSentencia === 2 ){                                
                                setResuelveRecurso( response?.data?.resuelveRecurso ?? false );
                                setMedioImpugnacionTemp( response?.data?.idMedioImpugnacion ?? 0 );
                                setSentidoFallo( response?.data?.idSentidoFallo ?? 0 );
                            }
                            
                            let cadenaA = '';
                            response?.data?.actor?.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response?.data?.demandado?.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                            if( response.data.proyectoSentencia ){
                                if ( response.data.idSentidoSentencia !== 0 && response.data.fechaCitacion )
                                {
                                    setActivarFinalizar( true );
                                }
                            }
                            else{                            
                                if ( response.data.idSentidoSentencia !== 0 && response.data.fechaCitacion && response.data.idPlantilla !== 0 )
                                {
                                    setActivarFinalizar( true );
                                }    
                            }                 

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 27 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 27){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    useEffect(() => {

        async function Obtener(){    

            await ObtenerSentidosFallo().then( response => {

                if( response ){
                    if( response.status === 200 ){
                        setSentidoFalloArray( response.data );
                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){    

            setMedioImpugnacion( 0 );
            setMedioImpugnacionArray( [] );

            await MediosImpugnacionSentencia({ idExpediente: sentencia?.idExpediente ?? 0 }).then( response => {

                if( response ){
                    if( response.status === 200 ){
                        setMedioImpugnacionArray( response.data );
                    }
                }

            });

        }

        if( sentencia?.idExpediente && resuelveRecurso ){
            Obtener();
        }

    }, [ resuelveRecurso, sentencia ]);

    useEffect(() => {

        if( medioImpugnacionTemp !== 0 && medioImpugnacionArray.length !== 0 ){
            setMedioImpugnacion( medioImpugnacionTemp );
        }

    }, [ medioImpugnacionTemp, medioImpugnacionArray ]);
 
    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box> 
                    )
                    :
                    (       
                        
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" sx={{ pt: 2 }}>
                                
                                <Tab label="Sentencia" { ...a11yProps(0) } />
                                <Tab label="Notificación" { ...a11yProps(1) } onClick={ handleChangeEditor } />

                            </Tabs>                              
                           
                            <TabPanel value={ valueTab } index={0}>

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GSE-' + sentencia?.idSentencia }
                                    actividad={ 'Elaborar sentencia' }
                                    tipo={ 'Número de expediente' }
                                    referencia={ sentencia?.numeroExpediente }
                                />

                                <Grid container spacing={3} mt={1}>                                 
                                   
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                            Número de Sentencia (Resolución):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={ tribunal ? 4 : 10 } xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { sentencia?.numeroSentencia }
                                        </Typography>
                                    </Grid>

                                    {
                                        juzgado && sentencia?.tipoSentencia
                                        &&
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Tipo de Sentencia:                                          
                                                </Typography>
                                            </Grid>
                                    }
                                    
                                    {
                                        juzgado && sentencia?.tipoSentencia
                                        &&
                                            <Grid  item md={10} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray'  }}>
                                                    { sentencia?.tipoSentencia }                                            
                                                </Typography>
                                            </Grid>
                                    }
                                    
                                    {
                                        sentencia?.numeroCierreInstruccion
                                        &&
                                            <Grid item md={2} xs={6}>                                            
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de Cierre de instrucción:
                                                </Typography>                                                                                        
                                            </Grid>
                                    }
                                    
                                    {
                                        sentencia?.numeroCierreInstruccion
                                        &&
                                            <Grid item md={4} xs={6} >                                            
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { sentencia?.numeroCierreInstruccion }
                                                </Typography>                                                
                                            </Grid> 
                                    }   
                                    
                                    {
                                        sentencia?.ponencia
                                        && 
                                            <Grid item md={2} xs={6}>                                                                                
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Ponencia:
                                                </Typography>                                                
                                            </Grid>
                                    }
                                    
                                    {
                                        sentencia?.ponencia
                                        &&  
                                            <Grid item md={10} xs={6} >                                                
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { sentencia?.ponencia }
                                                </Typography>                                                
                                            </Grid>
                                    }

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                        {
                                            sentencia?.actor?.map( ( elem, i ) => (
                                                <ListItem key={ i } disablePadding>  
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                        { elem }
                                                    </Typography>      
                                                </ListItem>
                                            ))
                                        }                      
                                        </List>
                                    </Grid>  
                                    
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List 
                                            disablePadding 
                                            sx={{ maxHeight: sentencia?.demandado?.length > 10 ? 300 : null, overflow: sentencia?.demandado?.length > 10 ? 'scroll' : null }}
                                        >  
                                        {
                                            sentencia?.demandado?.map( ( elem, i ) => (
                                                <ListItem key={ i } disablePadding>  
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                        { elem }
                                                    </Typography>      
                                                </ListItem>
                                            ))
                                        }                                              
                                        </List>
                                    </Grid>           

                                    <Grid item md={12} xs={12} > </Grid>
                                    
                                    <Grid item md={4} xs={12} >  

                                        <FormControl fullWidth>
                                            <InputLabel id="select-sentidoSentencia-label">Sentido de la sentencia</InputLabel>
                                        
                                            <Select
                                                labelId="select-sentidoSentencia-label"
                                                id="select-sentidoSentencia"                                            
                                                label="Sentido de la sentencia"
                                                name="sentidoSentencia"
                                                value={ sentidoSentencia }
                                                onChange={ ( e ) => { setSentidoSentencia( e.target.value ); } }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    sentidoSentenciaAray.map((elem)=>(
                                                        <MenuItem key={elem.idSentidoSentencia} value={ elem.idSentidoSentencia }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.sentidoSentencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.sentidoSentencia } </Alert> )
                                        }

                                    </Grid>  

                                    <Grid item md={4} xs={12} >
                                    
                                        <TextField                              
                                            label="Fecha citación"
                                            type="datetime-local"              
                                            fullWidth
                                            value={ fechaCitacion }    
                                            onChange={ ( e ) => { setFechaCitacion( e.target.value ); } } 
                                            inputProps={{
                                                max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                            }}                             
                                            InputLabelProps={{
                                                shrink: true,
                                            }}    
                                            disabled={ tribunal && fechaCitacion !== '' }         
                                        />
                                        {   
                                            errors.fechaCitacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaCitacion } </Alert> )
                                        }

                                    </Grid>                           

                                    <Grid item md={4} xs={12} >

                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">¿Con perspectiva género?</FormLabel>
                                            <RadioGroup row
                                                aria-label="seRevisaProyecto"
                                                defaultValue="si"
                                                name="radio-buttons-group"  
                                            >
                                                <FormControlLabel control={ <Radio checked={ perspectivaGenero === false } onChange={ () => { handleChangePerspectivaGenero( false ); } } />} label="No" />
                                                <FormControlLabel control={ <Radio checked={ perspectivaGenero === true } onChange={ () => { handleChangePerspectivaGenero( true ); } } />} label="Si" />  
                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>

                                    { 
                                        !sentencia?.proyectoSentencia
                                        &&                                
                                        <Grid item md={12} xs={12} >                            
                                            <FormControl fullWidth>
                                                <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                            
                                                <Select
                                                    labelId="select-plantilla-label"
                                                    id="select-plantilla"                                            
                                                    label="Plantilla"
                                                    name="plantilla"
                                                    value={ plantilla }
                                                    onChange={ (e) => { handleChangePlantilla(e.target.value) } }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        plantillaArray
                                                        .filter( ( element ) => { return element.idSubTipoAcuerdo === 8 || element.idSubTipoAcuerdo === 9 || element.idSubTipoAcuerdo === 36; })                                                
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {   
                                                    errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                                }
                                            </FormControl>
                                        </Grid>  
                                    }

                                    {
                                        ( sentencia?.idTipoSentencia === 2 && sentencia?.incidente === true )
                                        &&
                                        <>
                                            <Grid item md={12} xs={12} >

                                                <FormControl fullWidth>
                                                                                    
                                                    <Grid container spacing={3}>
                                                        
                                                        <Grid item md={6} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                            <FormLabel sx={{ color: 'black' }} >¿La sentencia resuelve un recurso?</FormLabel>
                                                        </Grid>
                                                        
                                                        <Grid item md={6} xs={12} >
                                                            <RadioGroup row >
                                                                <Grid container >
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="Si" control={ <Radio checked={ resuelveRecurso === true } onChange={ () => { handleResuelveRecurso( true ); } } /> } label="Si" />
                                                                    </Grid>
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="No" control={ <Radio checked={ resuelveRecurso === false } onChange={ () => { handleResuelveRecurso( false ); } } /> } label="No" />     
                                                                    </Grid>                                                            
                                                                </Grid>
                                                            </RadioGroup>
                                                        </Grid>

                                                    </Grid>

                                                </FormControl>

                                            </Grid>

                                            {
                                                resuelveRecurso
                                                &&
                                                    <Grid item md={12} xs={12} >
                                                
                                                        <Card>

                                                            <CardHeader 
                                                                sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                                                subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información del recurso </Typography> }
                                                            />
                                                        
                                                            <Divider />
                                                    
                                                            <CardContent> 

                                                                <Grid container spacing={3}>

                                                                    <Grid item md={12} xs={12} >                            
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="select-medioImpugnacion-label">Seleccione el recurso</InputLabel>
                                                                        
                                                                            <Select
                                                                                labelId="select-medioImpugnacion-label"
                                                                                id="select-medioImpugnacion"                                            
                                                                                label="Seleccione el recurso"
                                                                                name="parte"
                                                                                value={ medioImpugnacion }
                                                                                onChange={ ( e ) => { setMedioImpugnacion( e.target.value ); } }             
                                                                                disabled={ !edit }                                                                                                       
                                                                            >
                                                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>

                                                                                {
                                                                                    medioImpugnacionArray
                                                                                    .map((elem)=>(
                                                                                        <MenuItem key={ elem.idMedioImpugnacion } value={ elem.idMedioImpugnacion }>                                                                                
                                                                                            <strong>Tipo de recurso:</strong>&nbsp;{ elem?.tipoApelacion ?? '' },&nbsp;&nbsp;
                                                                                            <strong>Tipo de resolución:</strong>&nbsp;{ elem?.tipoResolucion ?? '' },&nbsp;&nbsp;
                                                                                            <strong>Fecha de admisión:</strong>&nbsp;{ elem?.fechaAdmision ? moment( elem?.fechaAdmision ).format('DD-MM-yyy') : '' },&nbsp;&nbsp;
                                                                                            <strong>Recurrente:</strong>&nbsp;{ elem?.recurrente ?? '' }
                                                                                        </MenuItem>
                                                                                    ))                                                                                   
                                                                                } 

                                                                            </Select>

                                                                        </FormControl>
                                                                        {   
                                                                            errors.medioImpugnacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.medioImpugnacion } </Alert> )
                                                                        }
                                                                    </Grid>  

                                                                    <Grid item md={12} xs={12} >                            
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="select-sentidoFallo-label">Sentido de fallo</InputLabel>
                                                                        
                                                                            <Select
                                                                                labelId="select-sentidoFallo-label"
                                                                                id="select-sentidoFallo"                                            
                                                                                label="Sentido de fallo"
                                                                                name="sentidoFallo"
                                                                                value={ sentidoFallo }
                                                                                onChange={ ( e ) => { setSentidoFallo( e.target.value ); } }             
                                                                                disabled={ !edit }                                                                                                       
                                                                            >
                                                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>

                                                                                {
                                                                                    sentidoFalloArray
                                                                                    .map((elem)=>(
                                                                                        <MenuItem key={ elem.idSentidoFallo } value={ elem.idSentidoFallo }>{ elem.descripcion }</MenuItem>
                                                                                    ))                                                                                   
                                                                                } 

                                                                            </Select>

                                                                        </FormControl>
                                                                        {   
                                                                            errors.sentidoFallo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.sentidoFallo } </Alert> )
                                                                        }
                                                                    </Grid>   

                                                                </Grid>                                                

                                                            </CardContent>

                                                        </Card>

                                                    </Grid>
                                            }

                                        </>
                                    }

                                    <Grid item md={12} xs={12} >  
                                        
                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = { richText }
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak |  Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
                                                                    var items = [];
                                                                    
                                                                    if( sentencia?.ponencia ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de sentencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + sentencia.numeroSentencia + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + sentencia.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ sentencia.tipoJuicio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ sentencia.ponencia +'</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else{                                                                
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de sentencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + sentencia.numeroSentencia + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + sentencia.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ sentencia.tipoJuicio + '</strong>'); } },
                                                                            
                                                                        ];
                                                                    }
                                                                    callback(items);
                                                                }
                                                            });
                                                        },                                                 
                                                        content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                                }
                                            }  
                                        />

                                        {   
                                            errors.contenido && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.contenido } </Alert> )
                                        }

                                    </Grid>   
                                    
                                    {
                                        sentencia?.observaciones
                                        &&
                                        <>
                                            <Grid item md={3} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray'  }}>
                                                    Motivo de no autorización:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { sentencia?.observaciones }
                                                </Typography>
                                            </Grid>
                                        </>
                                    } 

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                        <LoadingButton 
                                            variant="contained" 
                                            onClick={ handleGuardarSentencia } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar sentencia
                                        </LoadingButton>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                        
                                        <Button 
                                            variant="contained" 
                                            onClick={ () => { setOpenConfirmacion( true ); } } 
                                            endIcon={ <CheckCircleIcon /> } 
                                            disabled = { !activarFinalizar || ( resuelveRecurso && ( medioImpugnacion === 0 || sentidoFallo === 0 ) ) }
                                        > 
                                            Finalizar 
                                        </Button>
                                    
                                    </Grid>       

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}>                        
                                
                                <Grid item md={12} xs={12}>
                                    <Typography variant="h6" sx={{ color: 'gray', mb: 1 }} >
                                        Número de seguimiento: GSE-{ sentencia?.idSentencia }
                                    </Typography>
                                </Grid>

                                <SentenciaNotificacionScreen 
                                    Id={ Id } 
                                    edit={ true } 
                                    notificaciones={ sentencia?.notificaciones ?? [ ] } 
                                    partes={ partes }
                                    sentencia={ sentencia } 
                                    setSentencia={ setSentencia } 
                                />

                            </TabPanel>

                        </Container>
                
                    )

                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar el registro de la sentencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
