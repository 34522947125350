import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';

import { Alert,  Box, Container, Card,CardContent, Grid, List, ListItem, Paper, Typography, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { types } from '../../../types/types';

import { ObtenerOficioDetalle, ActualizarEstatusOficio } from '../../../actions/tribunal/oficioActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const VisualizarOficioScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);
    
    const [oficio, setOficio] = useState( [ ] );

    const [loadingFinalizar, setLoadingFinalizar] = useState( false );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [referenciaA, setReferenciaA] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const Finalizar = async () => {

        setLoadingFinalizar( true );
        
        const params = {
            'idOficio' : Id,
            'idEstatus': 47,
        };

        await ActualizarEstatusOficio( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );
                        
                        setLoadingFinalizar( false );

                        setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                        setSendAjax( true );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idOficio': Id
            };

            dispatch({ type: types.openLoading });

            await ObtenerOficioDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setOficio(response.data);

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferenciaA( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferenciaA( response?.data?.numeroExhorto ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhortoLibrado ?? '') !== '' ){
                                setTipoReferencia( 'Número de exhorto librado' );
                                setReferenciaA( response?.data?.numeroExhortoLibrado ?? '' );
                            } 
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferenciaA( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferenciaA( response?.data?.numeroExpediente ?? '' );
                            }   
      
                            setAjaxLoading( true );
    
                            dispatch({ type: types.closeLoading });

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 36 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 36){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }

                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                            
                            <InformacionSeguimiento
                                noSeguimiento={ 'GOF-' + oficio.idOficio }
                                actividad={ 'Visualizar oficio' }
                                tipo={ tipoReferencia }
                                referencia={ referenciaA }
                            />    

                            <Grid container spacing={3} mt={1}>                                 

                                <Grid item md={2} xs={3}>
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Número de oficio:
                                    </Typography>
                                </Grid>

                                <Grid item md={10} xs={9} >
                                    <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                        { oficio.numeroOficio }
                                    </Typography>
                                </Grid>

                                { 
                                    (oficio.numeroExpediente !== null && oficio.expediente.ponencia === null) &&
                                    
                                    <>                                       
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Actor(es):
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                           
                                            {
                                                oficio.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                 
                                            </List> 
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Demandado(s):
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List 
                                                disablePadding 
                                                sx={{ maxHeight: oficio?.demandado?.length > 10 ? 300 : null, overflow: oficio?.demandado?.length > 10 ? 'scroll' : null }}
                                            >                      
                                            {
                                                oficio.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                 
                                            </List> 
                                        </Grid>
                                    </>
                                }  

                                { 
                                    (oficio.numeroExpediente !== null && oficio.expediente.ponencia !== null) &&
                                    <>                                        
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Ponencia:
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={10} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { oficio?.expediente?.ponencia?.descripcion }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Actor(es):
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                           
                                            {
                                                oficio.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                 
                                            </List> 
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Demandado(s):
                                            </Typography>   
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List 
                                                disablePadding 
                                                sx={{ maxHeight: oficio?.demandado?.length > 10 ? 300 : null, overflow: oficio?.demandado?.length > 10 ? 'scroll' : null }}
                                            >                        
                                            {
                                                oficio.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                 
                                            </List> 
                                        </Grid>
                                    </> 
                                }  

                                { 
                                    oficio.numeroIrregular !== null && 
                                    <>     
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Tipo de irregular:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { oficio?.irregular?.tipoIrregular?.descripcion }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                           
                                            {
                                                oficio.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                 
                                            </List>      
                                        </Grid>
                                        
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                    {
                                                        oficio.demandado.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem }
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                                                                                
                                            </List>
                                        </Grid> 
                                    </>
                                } 

                                { oficio.numeroAmparo !== null && 
                                    <>                                   
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Tipo de amparo:
                                            </Typography>
                                        </Grid>
            
                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { oficio.amparo.tipoAmparo.descripcion }
                                            </Typography>
                                        </Grid> 

                                        <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de oficio:
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio.subTipoAcuerdo }
                                                </Typography>
                                            </Grid> 
            
                                        <Grid item md={2} xs={3}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Partes(es):
                                            </Typography>
                                        </Grid>
            
                                        <Grid item md={10} xs={9} >
                                            <List disablePadding>                               
                                                {
                                                    oficio.partes.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                            </List>                            
                                    
                                        </Grid> 
                                    </>
                                }  
                                    { oficio.exhortoRecibido !== null && 
                                       <>                                           
                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de juicio:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.tipoJuicio?.descripcion }
                                                </Typography>                                
                                            </Grid> 
                
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                               
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                                </List>                            
                                        
                                            </Grid> 
                                    
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                    
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                    {
                                                        oficio.demandado.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem }
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                                  
                                                </List>
                                            </Grid>    

                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Autoridad del exhorto:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.autoridadExhorto?.descripcion }
                                                </Typography>                                
                                            </Grid>                             
                                            
                                            <Grid item md={2} xs={6}>    
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&                                                     
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                        Ponencia:
                                                    </Typography>   
                                                }                             
                                            </Grid>                            
                
                                            <Grid item md={4} xs={6} >
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&       
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { oficio?.exhortoRecibido?.ponencia?.descripcion }
                                                    </Typography>
                                                }
                                            </Grid> 
                                       </>
                                    }

                                <Grid item lg={12} md={12} xs={12}>

                                    {
                                        oficio?.documentoFirmado
                                        ?
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ oficio?.documentoFirmado }  
                                            />
                                        :                                        
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ "data:application/pdf;base64," + oficio?.documento }  
                                            />
                                    }

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 

                                    <Button 
                                        variant="contained" 
                                        onClick={ () => { setOpenConfirmacion( true ); } } 
                                        endIcon={ <CheckCircleIcon /> }
                                    > 
                                        Finalizar 
                                    </Button>

                                </Grid>    

                            </Grid>

                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la visualización del oficio?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
