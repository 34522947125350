import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Select, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AppBarMenu from '../../ui/AppBarMenu';

import SearchIcon from '@mui/icons-material/Search';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import moment from 'moment';

import '../../../css/index.css';

import { ObtenerTiposConsecutivos } from '../../../actions/admin/catalogs/tipoConsecutivoActions';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';

import { EliminarCaratulaHipotecario, GenerarCaratulaHipotecario, ObtenerExpedienteVirtual_Expediente } from '../../../actions/recepcion/expedienteActions';

import { EliminarCaratulaExhortoRecibidoHipotecario, GenerarCaratulaExhortoHipotecario, ObtenerExpedienteVirtual_ExhortoRecibido } from '../../../actions/recepcion/exhortoRecibidoActions';
import { EliminarCaratulaIrregularHipotecario, GenerarCaratulaIrregularHipotecario, ObtenerExpedienteVirtual_Irregular } from '../../../actions/recepcion/irregularActions';
import { EliminarCaratulaAmparoHipotecario, GenerarCaratulaAmparoHipotecario, ObtenerExpedienteVirtual_Amparo } from '../../../actions/recepcion/amparoActions';

export const GenerarCaratulaScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );

    const [numeroReferencia, setNumeroReferencia] = useState( '' );
    
    const [expediente, setExpediente] = useState( {} );
    const [exhortoRecibido, setExhortoRecibido] = useState( {} );
    const [irregular, setIrregular] = useState( {} );
    const [amparo, setAmparo] = useState( {} );

    const [loading, setLoading] = useState( false );

    const [tipoConsecutivo, setTipoConsecutivo] = useState( 0 );
    const [tipoConsecutivoArray, setTipoConsecutivoArray] = useState( [] );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [openDocumento, setOpenDocumento] = useState( false );
    const [fullScreen, setFullScreen] = useState( false );
    const [caratula, setCaratula] = useState( false );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [loadingGenerar, setLoadingGenerar] = useState( false );

    const [alert, setAlert] = useState( {  } );

    const handleCriteriosBusqueda = () => {

        setExpediente( {} );      
        setExhortoRecibido( {} );
        setIrregular( {} );
        setAmparo( {} );
        setCaratula( '' );

        if( tipoConsecutivo === 1 ){
            handleBuscarExpediente();
        }
        else if( tipoConsecutivo === 8 ){
            handleBuscarExhortoRecibido();
        }
        else if( tipoConsecutivo === 2 ){
            handleBuscarIrregular();
        }
        else if( tipoConsecutivo === 7 ){
            handleBuscarAmparo();
        }
    }

    const handleBuscarExpediente = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Expediente({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setExpediente( response.data );
                            setCaratula( response?.data?.caratula ?? '' );

                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el expediente ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );             
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarExhortoRecibido = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_ExhortoRecibido({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setExhortoRecibido( response.data );      
                            setCaratula( response?.data?.caratula ?? '' );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el exhorto ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarIrregular = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Irregular({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );
                            setCaratula( response?.data?.caratula ?? '' );
                            
                            setIrregular( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el irregular ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarAmparo = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Amparo({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setAmparo( response.data );
                            setCaratula( response?.data?.caratula ?? '' );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el amparo ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleGenerarCaratula = async () => {
        
        setLoadingGenerar( true );

        if( expediente?.success ){
        
            await GenerarCaratulaHipotecario( expediente?.idExpediente ?? '' ).then( resp => {

                if( resp ){

                    const { success, caratula: caratulaResp, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( caratulaResp ); 
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });

        }
        else if( exhortoRecibido?.success ){      

            await GenerarCaratulaExhortoHipotecario( exhortoRecibido?.idExhortoRecibido ?? '' ).then( resp => {

                if( resp ){

                    const { success, caratula: caratulaResp, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( caratulaResp ); 
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }     
        else if( amparo?.success ){        

            await GenerarCaratulaAmparoHipotecario( amparo?.idAmparo ?? '' ).then( resp => {

                if( resp ){

                    const { success, caratula: caratulaResp, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( caratulaResp ); 
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }    
        else if( irregular?.success ){        

            await GenerarCaratulaIrregularHipotecario( irregular?.idIrregular ?? '' ).then( resp => {

                if( resp ){

                    const { success, caratula: caratulaResp, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( caratulaResp ); 
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }   

    }

    const handleEliminarCaratula = async () => {
        
        setLoadingGenerar( true );

        if( expediente?.success ){
        
            await EliminarCaratulaHipotecario( expediente?.idExpediente ?? '' ).then( resp => {

                if( resp ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( '' ); 
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });

        }
        else if( exhortoRecibido?.success ){     
            
            await EliminarCaratulaExhortoRecibidoHipotecario( exhortoRecibido?.idExhortoRecibido ?? '' ).then( resp => {

                if( resp ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( '' ); 
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }     
        else if( amparo?.success ){        

            await EliminarCaratulaAmparoHipotecario( amparo?.idAmparo ?? '' ).then( resp => {

                if( resp ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( '' ); 
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }     
        else if( irregular?.success ){        

            await EliminarCaratulaIrregularHipotecario( irregular?.idIrregular ?? '' ).then( resp => {

                if( resp ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } );   
            
                            setCaratula( '' ); 
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );   
            
                            setOpenConfirmacion( false );
                            setLoadingGenerar( false );
                
                        }, 600);
                    }
                }
            });
        }    

    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposConsecutivos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoConsecutivoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <Box sx={{ marginTop: '5em' }}>  
               
                <Container maxWidth="xl" sx={{ mb: 5 }} >
                    
                    <Grid container spacing={3} sx={{ mt: { sm: 10 } }}  >
                        
                        <Grid item xs={12} sx={{ mb: { sm: 1 } }}>

                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}> 
                               
                                    <Card>

                                        <CardHeader 
                                            sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                            subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Tipo de carátula </Typography> } 
                                        />

                                        <Divider />

                                        <CardContent sx={{ mt: 1 }}>

                                            <Grid container spacing={3} >

                                                <Grid item md={4} sm={6} xs={12}> 
                                                
                                                    <FormControl fullWidth>
                                                        <InputLabel id="select-tipoConsecutivo-label">Tipo de consecutivo</InputLabel>
                                                    
                                                        <Select
                                                            labelId="select-tipoConsecutivo-label"
                                                            id="select-tipoConsecutivo"                                            
                                                            label="Tipo de consecutivo"
                                                            name="tipoConsecutivo"
                                                            value={ tipoConsecutivo }                                
                                                            onChange={ ( e ) => setTipoConsecutivo( e.target.value ) }
                                                        >
                                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                            {
                                                                tipoConsecutivoArray
                                                                .filter( ( elem ) => { return elem.idTipoConsecutivo === 1 || elem.idTipoConsecutivo === 2 || elem.idTipoConsecutivo === 7 || elem.idTipoConsecutivo === 8; } )
                                                                .map( ( elem ) => (
                                                                    <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>    
                                                                ))
                                                            }  
                                                                                                
                                                        </Select>

                                                    </FormControl>
                                                
                                                </Grid>                                               

                                                {
                                                    ( administrador || coordinador )
                                                    &&     
                                                    <Grid item md={4} sm={6} xs={12}> 
                                                    
                                                        <FormControl fullWidth>
                                                            <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                        
                                                            <Select
                                                                labelId="select-autoridad-label"
                                                                id="select-autoridad"                                            
                                                                label="Autoridad"
                                                                name="autoridad"
                                                                value={ autoridad }                                
                                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                            >
                                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                                {
                                                                    coordinador
                                                                    ?
                                                                        autoridadArray 
                                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                                        .map( ( elem ) => (
                                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                        ))
                                                                    :
                                                                        autoridadArray.map( ( elem ) => (
                                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                        ))
                                                                }                          
                                                            </Select>

                                                        </FormControl>
                                                    
                                                    </Grid>
                                                }

                                                <Grid item md={4} sm={6} xs={12} > 

                                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                              
                                                        
                                                        <InputBase sx={{ ml: 1, flex: 1 }} inputProps={{ 'aria-label': 'Buscar por referencia' }} 
                                                            placeholder="Buscar por referencia"     
                                                            value={ numeroReferencia }
                                                            onChange={ ( e ) => setNumeroReferencia( e.target.value )  }
                                                        />

                                                        <LoadingButton 
                                                            sx={{ borderRadius: 10, mr: 1 }} 
                                                            variant='contained' 
                                                            onClick={ handleCriteriosBusqueda }
                                                            size='small'
                                                            loading={ loading }
                                                            disabled={
                                                                ( administrador || coordinador )
                                                                ?
                                                                    tipoConsecutivo === 0 ||
                                                                    autoridad === 0 ||
                                                                    numeroReferencia === ''
                                                                :
                                                                    tipoConsecutivo === 0 ||
                                                                    numeroReferencia === ''
                                                            }
                                                        >
                                                            <SearchIcon />
                                                        </LoadingButton> 

                                                    </Paper>

                                                </Grid>
                                            
                                            </Grid>

                                        </CardContent>
                                    
                                    </Card>

                                </Grid>

                            </Grid>

                        </Grid>                        
                        
                        <Grid item md={6} xs={12} >    

                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}> 

                                    <Card>

                                        <CardHeader 
                                            sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                            subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información del expediente </Typography> }                                           
                                        />
                                        
                                        <Divider />
                                    
                                        <CardContent>

                                            {
                                                loading
                                                ?
                                                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                                        <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                    </Box>
                                                :
                                                    <>
                                                        {
                                                            expediente?.success 
                                                            &&
                                                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Número de expediente: 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.numeroExpediente }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    
                                                                    {
                                                                        expediente?.numeroFolio
                                                                        &&
                                                                        <>
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Número de folio: 
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { expediente?.numeroFolio }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    }

                                                                    {
                                                                        expediente?.numeroExpedientePrincipal
                                                                        &&
                                                                        <>
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Número de expediente <br/> principal: 
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { expediente?.numeroExpedientePrincipal }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    }

                                                                    {
                                                                        expediente?.expedientesAcumulados?.length !== 0
                                                                        &&
                                                                            <>
                                                                            
                                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                                        <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                            Expediente Acumulados: 
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                                        <List disablePadding>
                                                                                            {
                                                                                                expediente?.expedientesAcumulados?.map( ( element, i  ) => (                                    
                                                                                                    <ListItem key={ i } disablePadding>                              
                                                                                                        <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                            { element }
                                                                                                        </Typography>                               
                                                                                                    </ListItem>
                                                                                                ))
                                                                                            }                       
                                                                                        </List>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            
                                                                            </>  
                                                                    }

                                                                    {
                                                                        expediente?.ponencia
                                                                        &&
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Ponencia:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { expediente?.ponencia }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>   
                                                                    }                                                             

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Tipo de juicio:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.tipoJuicio }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   

                                                                    {
                                                                        expediente?.tipoCalificacion
                                                                        &&                                                            
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Tipo de calificación:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { expediente?.tipoCalificacion }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>        
                                                                    }                                           

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>

                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Actor(es):
                                                                            </Typography>

                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    expediente?.actor?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                       
                                                                            </List>

                                                                        </Grid>

                                                                    </Grid>

                                                                    {
                                                                        expediente?.demandado?.length !== 0
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                            <Grid item md={4} xs={4}>
                                                                                <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Demandado(s):
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item md={8} xs={8} >
                                                                                <List 
                                                                                    disablePadding 
                                                                                    sx={{ maxHeight: expediente?.demandado?.length > 10 ? 200 : null, overflow: expediente?.demandado?.length > 10 ? 'scroll' : null }}
                                                                                >  
                                                                                    {
                                                                                        expediente?.demandado?.map( ( element, i  ) => (                                    
                                                                                            <ListItem key={ i } disablePadding>                              
                                                                                                <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                    { element }
                                                                                                </Typography>                               
                                                                                            </ListItem>
                                                                                        ))
                                                                                    }                             
                                                                                </List>
                                                                            </Grid>

                                                                        </Grid>   
                                                                    }   

                                                                    {
                                                                        expediente?.prestaciones?.length !== 0
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                            <Grid item md={4} xs={4}>
                                                                                <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Prestacion(es):
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item md={8} xs={8} >
                                                                                <List disablePadding>
                                                                                    {
                                                                                        expediente?.prestaciones?.map( ( element ) => (                                    
                                                                                            <ListItem key={ element.idTipoPrestacion } disablePadding>                    
                                                                                                <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                    { element.descripcion }
                                                                                                </Typography>                                
                                                                                            </ListItem>
                                                                                        ))
                                                                                    }  
                                                                                </List>
                                                                            </Grid>

                                                                        </Grid>                                                                 
                                                                    }

                                                                    {
                                                                        expediente?.juez
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Juez:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { expediente?.juez }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }   

                                                                    {
                                                                        expediente?.secretario
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Secretario(a) de Acuerdo y Trámite:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { expediente?.secretario }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }   

                                                                    { 
                                                                        expediente?.estatus 
                                                                        &&                                                            
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Estatus:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { expediente?.estatus }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    }   

                                                                </Box>
                                                        }

                                                        {
                                                            exhortoRecibido?.success 
                                                            &&
                                                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Número de exhorto: 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                { exhortoRecibido?.numeroExhorto }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid> 

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Expediente de origen: 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                { exhortoRecibido?.numeroOrigen }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  

                                                                    {
                                                                        exhortoRecibido?.ponencia
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Ponencia:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { exhortoRecibido?.ponencia }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }                                                             

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Tipo de juicio:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { exhortoRecibido?.tipoJuicio }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   

                                                                    {
                                                                        exhortoRecibido?.claseExhorto
                                                                        &&
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Clase del exhorto:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { exhortoRecibido?.claseExhorto }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>   
                                                                    }

                                                                    {
                                                                        exhortoRecibido?.objetoExhorto
                                                                        &&
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Objeto del exhorto:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { exhortoRecibido?.objetoExhorto }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>  
                                                                    }

                                                                    {
                                                                        exhortoRecibido?.seNotifico
                                                                        &&                                                                
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Fecha diligencia:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { moment( exhortoRecibido.fechaDiligencia ).format('DD-MM-YYYY HH:mm a') } 
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>  
                                                                    }

                                                                    {
                                                                        exhortoRecibido?.fechaDevolucion
                                                                        &&                                                                
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Fecha devolución:
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                        { moment( exhortoRecibido.fechaDevolucion ).format('DD-MM-YYYY HH:mm a') } 
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>  
                                                                    }

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>

                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Actor(es):
                                                                            </Typography>

                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    exhortoRecibido?.actor?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                       
                                                                            </List>

                                                                        </Grid>

                                                                    </Grid>

                                                                    {
                                                                        exhortoRecibido?.demandado?.length !== 0
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                            <Grid item md={4} xs={4}>
                                                                                <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Demandado(s):
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item md={8} xs={8} >
                                                                                <List disablePadding>
                                                                                    {
                                                                                        exhortoRecibido?.demandado?.map( ( element, i  ) => (                                    
                                                                                            <ListItem key={ i } disablePadding>                              
                                                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                    { element }
                                                                                                </Typography>                               
                                                                                            </ListItem>
                                                                                        ))
                                                                                    }                             
                                                                                </List>
                                                                            </Grid>

                                                                        </Grid>   
                                                                    }  

                                                                    {
                                                                        exhortoRecibido?.secretario
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Secretario(a) de Acuerdo y Trámite:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { exhortoRecibido?.secretario }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }   
                                                                
                                                                </Box>
                                                        }

                                                        {
                                                            irregular?.success 
                                                            &&
                                                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Número de irregular: 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                { irregular?.numeroIrregular }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  

                                                                    {
                                                                        irregular?.ponencia
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Ponencia:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { irregular?.ponencia }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }  

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>

                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Actor(es):
                                                                            </Typography>

                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    irregular?.actor?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                       
                                                                            </List>

                                                                        </Grid>

                                                                    </Grid>

                                                                    {
                                                                        irregular?.demandado?.length !== 0
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                            <Grid item md={4} xs={4}>
                                                                                <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Demandado(s):
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item md={8} xs={8} >
                                                                                <List disablePadding>
                                                                                    {
                                                                                        irregular?.demandado?.map( ( element, i  ) => (                                    
                                                                                            <ListItem key={ i } disablePadding>                              
                                                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                    { element }
                                                                                                </Typography>                               
                                                                                            </ListItem>
                                                                                        ))
                                                                                    }                             
                                                                                </List>
                                                                            </Grid>

                                                                        </Grid>   
                                                                    }   

                                                                    {
                                                                        irregular?.secretario
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Secretario(a) de Acuerdo y Trámite:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { irregular?.secretario }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }   

                                                                    { 
                                                                        irregular?.estatus 
                                                                        &&                                                            
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Estatus:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { irregular?.estatus }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    }

                                                                </Box>
                                                        }

                                                        {
                                                            amparo?.success 
                                                            &&
                                                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Número auxiliar de amparo: 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                { amparo?.numeroAmparo }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                    
                                                                    {
                                                                        amparo?.numeroAmparoOrigen
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Número de amparo de origen:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { amparo?.numeroAmparoOrigen }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    } 

                                                                    {
                                                                        amparo?.numeroExpediente
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Número de expediente:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { amparo?.numeroExpediente }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    } 

                                                                    {
                                                                        amparo?.ponencia
                                                                        &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Ponencia:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { amparo?.ponencia }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                    }  

                                                                    {
                                                                        amparo?.fecha
                                                                        &&                                                                
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Fecha del amparo:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { moment( amparo.fecha ).format('DD-MM-YYYY HH:mm a') } 
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    }

                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>

                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Parte(s):
                                                                            </Typography>

                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    amparo?.partes?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disableGutters>                              
                                                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                { element.parte } <br /> <strong>({ element.tipoParte })</strong>
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                       
                                                                            </List>

                                                                        </Grid>

                                                                    </Grid> 

                                                                    { 
                                                                        amparo?.autoridadAmparo 
                                                                        &&                                                            
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Autoridad del amparo:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { amparo?.autoridadAmparo  }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    }

                                                                    { 
                                                                        amparo?.sentidoAmparo 
                                                                        &&                                                            
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Sentido del amparo:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { amparo?.sentidoAmparo  }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                    }

                                                                </Box>
                                                        }

                                                    </>                                                   
                                            }

                                        </CardContent>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item md={6} xs={12} >   

                            <Card id="CardEV">

                                <CardHeader id="CardHeaderEV" 
                                    sx={{ backgroundColor: (theme) => theme.palette.primary.main }}                                 
                                    subheader={ 
                                        <Typography sx={{ fontWeight: '400', color: 'white' }}>                                             
                                            Carátula
                                        </Typography> 
                                    }                                     
                                />                                 
                                
                                <Divider />
                            
                                <CardContent  >

                                    {
                                        loading
                                        ?
                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                                <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                            </Box>
                                        :
                                            <>
                                                {
                                                   ( 
                                                        ( expediente?.success && caratula ) ||
                                                        ( exhortoRecibido?.success && caratula ) ||
                                                        ( amparo?.success && caratula ) ||
                                                        ( irregular?.success && caratula )
                                                    )
                                                    &&
                                                        <Box textAlign='end' mt={-2}>
                                                            <IconButton
                                                                aria-label="close"
                                                                onClick={ () => { setOpenDocumento( true ); setFullScreen( (prev) => !prev ) } }
                                                                sx={{ color: (theme) => theme.palette.grey[500], }}
                                                            >
                                                                <FullscreenIcon /> 
                                                            </IconButton>

                                                            <iframe 
                                                                title='caratula' 
                                                                src={ caratula } 
                                                                height={ fullScreen ? "100%" : '500px' }
                                                                width="100%">                                                
                                                            </iframe>  
                                                        </Box>                                  
                                                }      

                                                {
                                                   ( 
                                                        ( expediente?.success && caratula ) ||
                                                        ( exhortoRecibido?.success && caratula ) ||
                                                        ( amparo?.success && caratula ) ||
                                                        ( irregular?.success && caratula )
                                                   )
                                                    ?       
                                                        <Box
                                                            style={{ justifyContent: 'center' }}
                                                            textAlign='center'
                                                        >                                    
                                                            <Button 
                                                                variant='contained'
                                                                color='inherit'
                                                                startIcon={ <FileCopyIcon /> }
                                                                sx={{ mt: 1 }}             
                                                                onClick={ () => setOpenConfirmacion( true ) }                                
                                                            >
                                                                Eliminar carátula
                                                            </Button>  
                                                        </Box>
                                                    :
                                                        (
                                                            ( expediente?.success ) ||
                                                            ( exhortoRecibido?.success ) ||
                                                            ( amparo?.success ) ||
                                                            ( irregular?.success )
                                                        )
                                                        &&
                                                            <Box
                                                                style={{ height: '45vh', justifyContent: 'center' }}
                                                                textAlign='center'
                                                            >

                                                                <LoadingButton 
                                                                    variant='contained'
                                                                    startIcon={ <FileCopyIcon /> }
                                                                    sx={{ mt: 20 }}
                                                                    onClick={ handleGenerarCaratula }
                                                                    loading={ loadingGenerar }
                                                                >
                                                                    Generar carátula
                                                                </LoadingButton>            

                                                            </Box>
                                                } 
                                            </>      
                                    }    

                                </CardContent>
                                
                            </Card>     

                        </Grid>

                    </Grid>                    

                </Container>  

            </Box>

            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="md" fullScreen={ fullScreen } >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"> </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setFullScreen( (prev) => !prev ) } }
                    sx={{
                        position: 'absolute',
                        right: 55,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    { fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon /> } 
                </IconButton>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setOpenDocumento( false ); setFullScreen( false ); } }
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>              

                    <Grid container spacing={3} height={'100%'} >  
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>

                            {
                                caratula
                                ?
                                    <iframe title='caratula' src={ caratula } height={ fullScreen ? "100%" : '500px' } width="100%"></iframe>
                                :
                                    <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                        No se encontró la carátula
                                    </Typography>
                            }

                        </Grid>                        
                    </Grid>        

                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={ () => { setOpenDocumento( false ); setFullScreen( false ); } }>
                        Cerrar
                    </Button>
                </DialogActions>                

            </Dialog>  
            
            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar la carátula?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenConfirmacion( false ) }> Cancelar </Button>
                    <LoadingButton onClick={ handleEliminarCaratula } variant='contained' loading={ loadingGenerar } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
