import HttpClienteToken from '../../../services/HttpClienteToken';

export const ObtenerSentidosFallo = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/SentidoFallo/ObtenerSentidosFallo', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const SentidosFallo = ( ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/SentidoFallo/', token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const ActualizarSentidoFallo = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/SentidoFallo/Actualizar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}

export const AgregarSentidoFallo = ( params ) => {

    return new Promise( (resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data'));

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/SentidoFallo/Agregar', params, token)
            .then( response => {
                if( response.status === 200 ){
                    resolve( response );
                }
            })
            .catch( (error) => {
                resolve( error.response );
            });
        }

    });

}